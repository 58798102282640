// Customizable Area Start
import React from "react";
import {
  plusicon,
  closeIcon,
  uploadIcon,
  imgIcon,
  viewIcon,
  likedIcon,
  likeIcon,
  profileIcon,
  arrowIcon,
  rightArrow,
  leftArrowNew,
} from "../src/assets";

import LandingPageController, { Props } from "./LandingPageController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Cropper from "react-easy-crop";
import ToastMSG from "../../../components/src/ToastMSG.web";
import DiscoverProject from "./discoverproject/DiscoverProject.web";
import WorkOpportunity from "./workopportunity/WorkOpportunity.web";
import Catalogue from "../../catalogue/src/Catalogue.web"
import { Box, Container, Typography } from "@material-ui/core";
import { styled } from '@mui/system';
import { Button } from "@mui/material";

const HighlightUsername = styled(Typography) ({
  color: '#fff2e3',
  fontSize: '15px',
  textAlign: 'center',
  fontFamily: 'inter, sans-serif',
  marginTop: '15px',
  textTransform: 'capitalize',
  '&.username_you': {
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
  },
});

 const StyledContainer = styled(Box)({
  padding: '0 48px',
  boxSizing: 'border-box',
  margin: '0',
  marginTop: '96px',
});

 const HighlightHeading = styled(Typography) ({
  color: '#fff2e3',
  fontSize: '24px',
  fontWeight: 600,
  marginBottom: '25px',
  fontFamily: 'Inter, sans-serif',
});

const StyledImage = styled('img')({
  width: '17px',
  height: '17px',
});

const ButtonImage = styled('img')({
  borderRadius: '100%',
  width: '100%',
  height: '100%',
});

const HighlightContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  overflow: 'auto',
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    width: '0',
    height: '0',
  },
});

const styles = {
  highlightUsername: {
    fontWeight: 700,
    textTransform: 'capitalize',
    fontSize: 15,
    '@media (max-width: 1400px)': {
      fontSize: '8px',
    },
    '@media (max-width: 1300px)': {
      fontSize: '7px',
    },
  },
  dateContainer: {
    fontWeight: 'normal',
    marginRight: 23,
    marginLeft: 'auto',
    marginTop: 16,
    width: 'max-content',
    lineHeight: '24px',
    letterSpacing: '0.38px',
    fontSize: 12,
    '@media (max-width: 1450px)': {
      fontSize: '10px',
      marginRight: '12px',
      marginTop: '10px !important',
    },
    '@media (max-width: 1400px)': {
      fontSize: '10px',
      marginRight: '12px',
      marginTop: '10px !important',
    },
    '@media (max-width: 1300px)': {
      fontSize: '10px !important',
    },
  },
  sideCard: {
    width: 226.21,
    height: 414.58,
    marginLeft: -10,
    marginRight: -10,
    opacity: 0.4,
    background: 'none',
    '@media (max-width: 1860px)': {
      width: '220px',
      height: '350px',
      '& "highlight-username-view"': {
        left: '60px',
      }
    },
    '@media (max-width: 1800px)': {
      width: '220px',
      height: '350px',
      '& "highlight-username-view"': {
        left: '60px',
      }
    },
    '& highlight-view-box-wrapper': {
      marginTop:  0,
      fontSize: '6px',
      '& img': {
        width: '10px',
      },
    },
    '@media (max-width: 1750px)': {
      width: '210px',
      height: '350px',
      '& "highlight-username-view"': {
        left: '75px',
      }
    },
    '@media (max-width: 1600px)': {
      width: '180px',
      height: '320px',
      '& "highlight-username-view"': {
        left: '85px',
      }
    },
    '@media (max-width: 1450px)': {
      width: '173px',
      height: '320px',
      '& "highlight-username-view"': {
        left: '72px',
      }
    },
    '@media (max-width: 1400px)': {
      width: '162px',
      height: '300px',
      '& "highlight-username-view"': {
        left: '72px',
      }
    },
    '@media (max-width: 1300px)': {
      width: '153px',
      height: '280px',
      }
  },

  'highlight-username-view': {
    display: 'flex',
    gridGap: '11px',
    marginRight: '60px',
    width: '240px',
    '@media (max-width: 1860px)': {
      width: '210px',
    },
    '@media (max-width: 1800px)': {
      width: '210px',
    },
    '@media (max-width: 1750px)': {
      width: '200px',
    },
    '@media (max-width: 1600px)': {
      width: '150px',
    },
    '@media (max-width: 1450px)': {
      width: '110px',
    },
  },
  'highlight-view-card': {
    marginTop: '50px',
    padding: 10,
    borderRadius: 4,
    zIndex: 2,
    background: 'none',
    backgroundRepeat: 'no-repeat',
    color: '#FFF2E3',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '@media (max-width: 1860px)': {
      width: '460px',
      height: '650px',
    },
    '@media (max-width: 1800px)': {
      width: '460px',
      height: '650px',
    },
    '@media (max-width: 1750px)': {
      width: '420px',
      height: '650px',
    },
    '@media (max-width: 1600px)': {
      width: '400px',
      height: '650px',
    },
    '@media (max-width: 1450px)': {
      width: '350px',
      height: '650px',
    },
    '@media (max-width: 1400px)': {
      width: '350px',
      height: '580px',
    },
    '@media (max-width: 1300px)': {
      width: '330px',
      height: '530px',
    },
  },
  opacityZero : {
    opacity:0,
  },

  displayNone: {
    display: 'none',
  },
  'story-upload-btn': {
    height: '38px',
    width: '38px !important',
    minWidth: "38px !important",
    borderRadius: '50%',
    border: '1px solid #323232',
    backgroundColor: '#323232',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '-10px',
    right: '-10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  closebtn : { 
    position: 'absolute',
    top: '-30px',
    right: '-12px',
    padding: '8px 20px',
    float: 'right',
    color: '#ffffff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    background: 'transparent',
  },
  "highlight-story-box-wrapper":{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  'highlight-story-box': {
    height: '95.44px',
    width: '95.44px',
    // minWidth: '80px',
    borderRadius: '100%',
    border: '2px solid #373737',
    padding: '5px',
    margin: '0 13px',
  },
  'upload-story': {
    marginLeft: 0,
    position: 'relative',
  },
  'story-active': {
    border: '2px solid white',
    cursor: 'pointer',
  },
  'highlight-story-image':{
    borderRadius: '100%',
    height: '80px',
    width: '80px',
  },
  'no-story':{
    borderColor: 'transparent',
  },
  'highlight-popup-heading': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  'highlight-popup-heading-width': {
    width: '100%',
  },
  discardBtn: {
    backgroundColor: '#0d0d0d',  
    color: '#fff',
    border: '2px solid #0d0d0d',
    borderRadius: '3px',
    fontSize: '9px',  
    padding: '4px 10px',
  },
  saveProgressBtn: {
    backgroundCOlor: '#fff2e3',
    color: '#0d0d0d',
    border: '2px solid #fff2e3',
    borderRadius: '3px',
    fontSize: '9px',
    padding: '3px 10px',
    marginLeft: '10px'
  },
  'highlight-popup-body': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    gap: '15px',
  },
  cornerBox: {
    width: '20px',
    height: '20px',
    border: '6px solid #fff2e3',
    position: 'absolute',
    zIndex: 2,
  },
  cornerBox1: {
    left: '31.2%',
    borderRightStyle: 'none',
    borderBottomStyle: 'none',
    top: '11.4%',
    borderRadius: '4px 0px 0px 0px',
  },
  cornerBox2: {
    right: '31.2%',
    borderBottomStyle: 'none',
    borderLeftStyle: 'none',
    top: '11.4%',
    borderRadius: '0px 4px 0px 0px',
  },
  cornerBox3: {
    right: '31.2%',
    bottom: '11.4%',
    borderLeftStyle: 'none',
    borderTopStyle: 'none',
    borderRadius: '0px 0px 4px 0px',
  },
  cornerBox4: {
    left: '31.2%',
    bottom: '11.4%',
    borderRightStyle: 'none',
    borderTopStyle: 'none',
    borderRadius: '0px 0px 0px 4px',
  },
  'highlight-wrapper': {
    bgcolor: '#111111',
    height: '100%',
    overflowX: 'hidden', 
    overflowY: 'scroll',
    position: 'relative', 
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
  },
  closeViewHighlightBackdrop: {
    zIndex: 2,
    position: 'absolute',
    top: '40px',
    left: '40px',
    color: '#fff2e3',
    fontFamily: 'Inter-SemiBold, sans-serif',
    background: 'none',
    border: '1px solid transparent',
    fontSize: '36px',
    lineHeight: '43.57px',
    textTransform: 'none',
    '& img': {
      marginRight: '19px',
      position: 'relative',
      bottom: '3px',
      width: '9px',
      height: '17px',
    },
  },
  controls: {
    position: 'absolute',
    bottom: '5px',
    left: '50%',
    width: '40%',
    transform: 'translateX(-50%)',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  imgIconFirst: {
    marginRight: '10px',
    width: '15px',
  },
  imgIconLast: {
    marginLeft: '10px',
    width: '25px',
  },
  'hightlight-viewer': {
    position: 'sticky',
    top: '77px',
    left: 0,
    width: '100%',
    display: 'flex',
    gap: '45px',
    paddingBottom: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '76px',
    '@media (max-width: 1860px)': {
      gap: '30px',
    },
    '@media (max-width: 1800px)': {
      gap: '30px',
    },
    '@media (max-width: 1750px)': {
      gap: '30px',
    },
    '@media (max-width: 1600px)': {
      gap: '30px',
    },
    '@media (max-width: 1450px)': {
      gap: '30px',
    },
    '@media (max-width: 1400px)': {
      gap: '30px',
    },
    '@media (max-width: 1300px)': {
      gap: '25px',
    },
  },
  'img_main':{
    position: 'absolute',
    borderRadius: '15px',
  },
  viewIconWrapper: {
    marginLeft: '10px',
    display: 'flex',
    height: '20px',
    alignItems: 'center',
    gap: '10px',
    fontWeight: 700,
    width: '40px',
    marginRight: '30px',
    marginTop: '7px',
  },
  viewIconImg: {
    width: '25px',
  },
  likeIconWrapper: {
    display: 'flex',
    height: '20px',
    alignItems: 'center',
    gap: '10px',
    fontWeight: 700,
    width: '40px',
    marginRight: '10px',
    marginTop: '7px',
    marginLeft: '30px',
    '& img': {
      width: '22px',
      height: '19px',
    },
    '@media (max-width: 1300px)': {
      width: '25px',
    },
  },
  wrapper: {
    display: 'flex',
    marginTop: '25px',
    fontSize: '12px',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    color: '#fff',
    gap: '3px',
    fontFamily: 'Inter, sans-serif',
    '& img': {
      width: '15px',
    },
  },"highlight-username-profile":{
    minWidth: '45px',
        maxWidth: '45px',
        height:'45px',
        borderRadius: '50%'
  },
  'highlight_top_view_bar_section':{
    background: '#7d7d7d',
    height: '3px',
    width: '100%',
    borderRadius: '5px',
  },
  'bar_section_selected': {
    backgroundColor: '#fff2e3',
  },

'highlight_top_view_bar': {
  display: 'flex',
  gap: '5px',
  margin: '7px',
},
rightArrow :{
'& img': {
  '@media (max-width: 1400px)': {
    marginLeft: '-15px',
  },
  '@media (max-width: 1300px)': {
    marginLeft: '-13px',
  },
}
}
};

export const configJSON = require("./config");

interface HighlightItem {
  data: {
    attributes: {
      is_viewed: boolean;
      is_liked: boolean;
      created_at:string;
      image_url: string;
      likes: number;
      views: number;
    };
  };
}

interface HighlightData {
  id: number;
  user_name: string;
  highlights: HighlightItem[];
  account_id: number;
  profile_image: string;
  background_color: null | string;
}

interface UserType {
  role_name: string;
}

interface HighlightListProps {
    allHighlightdata: HighlightData[];
    userInfo: {
      user_name: string;
  };
  handleViewHighlight: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, index: number) => void;
  uploadHighLightHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  itemsContainerRef: React.RefObject<HTMLDivElement>;
  scrollPosition: number;
  userType: string | null | UserType;
  userPhoto:string | null;
  renderProfilePictureWithoutHighlight:()=>void;
  handleScroll?: ()=>void;
  loaderRef?: React.RefObject<HTMLDivElement>;
  checkIsViewed: (id:number) => boolean;
  domainUrl:string | undefined;
  logindUserUnViewedIds:number[];
  renderProfilePictureWithDomain:( allHighlightdata:HighlightData[] , domainUrl:string | undefined) => React.ReactNode
  handleReturnSpliceValue:(name:string) => string;
  newHighlightPresent:boolean;
  viewedHighlightIds:number[];
  renderProfilePictureForOther: (item:HighlightData, domainUrl:string | undefined) => React.ReactNode
}
export const HighlightList = ({ allHighlightdata,newHighlightPresent,viewedHighlightIds,logindUserUnViewedIds, userInfo, renderProfilePictureWithoutHighlight, handleViewHighlight,renderProfilePictureWithDomain, uploadHighLightHandler, renderProfilePictureForOther ,itemsContainerRef, scrollPosition, userType, userPhoto,handleScroll,loaderRef,domainUrl,handleReturnSpliceValue, checkIsViewed  }: HighlightListProps) => {
    const isActiveHighlight = (index: number) => {
     
      return  allHighlightdata[index]?.account_id && checkIsViewed(allHighlightdata[index].account_id) ? styles['story-active']: {};
    }

    const isYouActive = () =>{
      let isIncluded =  logindUserUnViewedIds.every(element => viewedHighlightIds.includes(element))
      return newHighlightPresent && !isIncluded ? styles['story-active'] : {} 
    }

  return ( 
  <StyledContainer>
 <Box>
        <HighlightHeading variant="h2">
          Highlights
        </HighlightHeading>
        <Box sx={{ display: 'flex' }}>
    {allHighlightdata && allHighlightdata[0]&&
      allHighlightdata[0].user_name ==
        userInfo?.user_name && (
        <Box className="highlight-story-box-wrapper">
          <Button
            className="highlight-story-box upload-story"
            sx={{
              ...styles['highlight-story-box'],
              ...styles[ 'upload-story'],
              ...isYouActive(),
              ...isActiveHighlight(0),

            }}
            onClick={(e) => handleViewHighlight(e,0)}
          >
            {renderProfilePictureWithDomain(allHighlightdata,domainUrl)}
            {(userType == "designer"||userType == "2") && (
              <Button
                className="story-upload-btn"
                id="upload-btn"
                // onClick={(e) => uploadHighLightHandler(e)}
                sx={{
                 ...styles['story-upload-btn']
                }}
                >
                <StyledImage
                  src={plusicon}
                  alt="plus-icon"
                  id="plus-icon-id"
                  className="story-upload-icon"
                />
              </Button>
            )}
          </Button>
          <HighlightUsername className="username_you">
      You
    </HighlightUsername>     
      </Box>
      )}
        {allHighlightdata &&(userType == "designer"||userType == "2") && (
      !allHighlightdata.length ||
      allHighlightdata[0].user_name !=
        userInfo?.user_name) && (
        
        <Box className={`highlight-story-box-wrapper`} sx={{...styles["highlight-story-box-wrapper"]}}> 
          <Box
            className={`highlight-story-box upload-story no-story`} 
            sx={{
              ...styles['highlight-story-box'],
              ...styles['upload-story'],
              ...styles['no-story']
            }}
          >
            {renderProfilePictureWithoutHighlight()}
            {/* <ButtonImage
              src={userPhoto?`${configJSON.baseURL.baseURL}/${userPhoto}`:profileIcon}
              alt="story-box"
              className="highlight-story-image"
            /> */}
              <Button
                className="story-upload-btn"
                sx={{
                  ...styles['story-upload-btn']
                 }}
                id="upload-btn"
                onClick={(e) =>
                  handleViewHighlight(e,0)
                }
              >
                <StyledImage
                  src={plusicon}
                  alt="plus-icon"
                  id="plus-icon-id"
                  className="story-upload-icon"
                />
              </Button>
              
          </Box>
           <HighlightUsername className="username_you">
      You
    </HighlightUsername>  
        </Box>
      )}
    <HighlightContainer className="highlight-container" ref={loaderRef} >
      <Box className="items-outer-container" >
        <Box
          className="highlight-items-container"
          onScroll={handleScroll}
          style={{
            transform: `translateX(-${scrollPosition}px)`,
            display: 'flex',
            flexWrap: 'nowrap',
            transition: 'transform 0.3s ease',
          }}
        >
          {allHighlightdata &&
             allHighlightdata.map(
              (item: HighlightData, index: number, arr: HighlightData[]) => {
                
                if (index != 0 || userInfo.user_name!=arr[0].user_name){
                 
                  return (
                    <Box
                      key={item?.account_id}
                      className={`highlight-story-box-wrapper`} sx={{...styles["highlight-story-box-wrapper"]}}
                    >

                      <Button
                        className="highlight-story-box story-elm"
                        sx={{
                          ...styles['highlight-story-box'],
                          ...isActiveHighlight(index),
                        }}

                        onClick={(e) => handleViewHighlight(e, index)}
                      >
                        {renderProfilePictureForOther(item,domainUrl)}
                      </Button>
                      <Typography
                        className="highlight-username"
                        style={{
                          color: '#fff2e3',
                          fontSize: '15px',
                          textAlign: 'center',
                          fontFamily: 'inter, sans-serif',
                          marginTop: '15px',
                          textTransform: 'capitalize',
                          padding:"0px 5px"
                        }}
                      >
                        {handleReturnSpliceValue(item.user_name)}
                      </Typography>;
                    </Box>
                  );
                }
              }
            )}
        </Box>
      </Box>
    </HighlightContainer>
  </Box>
</Box>
</StyledContainer>
  )
}

class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }
  renderContentBasedOnUserRole = () => {
    if (this.state.clickedHighlightIndex !== null) {
      return null;
    }
  
    switch (this.state.userRole) {
      case 'designer':
        return (
          <>
            <WorkOpportunity {...this.props} />
            <DiscoverProject {...this.props} />
          </>
        );
      case 'client':
        return <>
        <Catalogue {...this.props}/>
        <DiscoverProject {...this.props} />
        </>
      default:
        return null;
    }
  };

  renderProfilePictureWithDomain = (allHighlightdata: HighlightData[], domainUrl: string | undefined): React.ReactNode => {
    const highlightData = allHighlightdata && allHighlightdata[0] && allHighlightdata[0];
  
    let imageurl = null;
    let color: string = "";
  
    if (highlightData) {
      if (highlightData.profile_image && highlightData.profile_image.length > 0 && highlightData.profile_image !== null) {
        imageurl = highlightData.profile_image;
      } else if (highlightData.background_color && highlightData.background_color.length > 0) {
        color = highlightData.background_color;
      }
    }
  
    let profilePicture;
    if (imageurl) {
      profilePicture = (
        <ButtonImage
          src={domainUrl + imageurl}
          alt="story-box"
          className="highlight-story-image"
        />
      );
    }else if (color && color.length > 0) {
      profilePicture = (
        <Box
          className="highlight-story-image"
          style={{ backgroundColor: color }}
        ></Box>
      );
    } else {
      profilePicture = (
        <ButtonImage
          src={profileIcon}
          alt="story-box"
          className="highlight-story-image"
        />
      );
    }
  
    return profilePicture;
  };


  renderProfilePictureForOther = (item: HighlightData, domainUrl: string | undefined): React.ReactNode => {
    let imageurl = null;
    let color: string = "";
  
    if (item) {
      if (item.profile_image && item.profile_image.length > 0 && item.profile_image !== null) {
        imageurl = item.profile_image;
      } else if (item.background_color && item.background_color.length > 0) {
        color = item.background_color;
      }
    }
  
    let profilePicture;
    if (imageurl) {
      profilePicture = (
        <ButtonImage
          src={domainUrl + imageurl}
          alt="story-box"
          className="highlight-story-image"
        />
      );
    } else if (color && color.length > 0) {
      profilePicture = (
        <Box
          className="highlight-story-image"
          style={{ backgroundColor: color }}
        ></Box>
      );
    } else {
      profilePicture = (
        <ButtonImage
          src={profileIcon}
          alt="story-box"
          className="highlight-story-image"
        />
      );
    }
  
    return profilePicture;
  };

renderProfileAndHighlight = () => {
  let profileHighlight;

  if (this.state.clickedHighlightIndex !== null) {
    const highlightData = this.state.allHighlightdata[this.state.clickedHighlightIndex];
    if (highlightData?.profile_image) {
      profileHighlight = (
        <img
          className="highlight-username-profile"
          style={{ ...styles['highlight-username-profile'] }}
          src={this.state.domainUrl + highlightData.profile_image}
        />
      );
    } else if (highlightData?.background_color && highlightData.background_color.length > 0) {
      profileHighlight = (
        <Box
          className="highlight-username-profile"
          style={{
            backgroundColor: highlightData.background_color,
            ...styles['highlight-username-profile']
          }}
        ></Box>
      );
    }  else {
      profileHighlight = (
        <img
          className="highlight-username-profile"
          style={{ ...styles['highlight-username-profile'] }}
          src={profileIcon}
        />
      );
    }
  } else {
    profileHighlight = (
      <img
        className="highlight-username-profile"
        style={{ ...styles['highlight-username-profile'] }}
        src={profileIcon}
      />
    );
  }

  return profileHighlight;
};

renderProfilePictureWithoutHighlight = () =>{
  let profilePicture;
  const {profile_image, background_color} = this.state.userInfo
  
    if (profile_image) {
      profilePicture = (
        <ButtonImage
          data-test-id="profile-image"
          src={this.state.domainUrl + profile_image}
        />
      );
    } else if (background_color) {
      profilePicture = (
        <Box
          className="highlight-username-profile"
          style={{
            backgroundColor: background_color,
            ...styles["highlight-story-image"]
          }}
        ></Box>
      );
    } else {
      profilePicture = (
        <ButtonImage
          src={profileIcon}
        />
      );
    }

  return profilePicture;
}

renderProfilePicture = () => {
  let profilePicture;

  if (this.state.clickedHighlightIndex !== null) {
    const highlightData = this.state.allHighlightdata[this.state.clickedHighlightIndex];
    if (highlightData?.profile_image) {
      profilePicture = (
        <img
          className="highlight-username-profile"
          style={{ ...styles['highlight-username-profile'] }}
          src={this.state.domainUrl + highlightData.profile_image}
        />
      );
    } else if (highlightData?.background_color && highlightData.background_color.length > 0) {
      profilePicture = (
        <Box
          className="highlight-username-profile"
          style={{
            backgroundColor: highlightData.background_color,
            ...styles['highlight-username-profile']
          }}
        ></Box>
      );
    } else {
      profilePicture = (
        <img
          className="highlight-username-profile"
          style={{ ...styles['highlight-username-profile'] }}
          src={profileIcon}
        />
      );
    }
  }

  return profilePicture;
};

  renderShowPopUp = () =>{
    return this.state.showPopup && (
    <Box className="highlight-popup-wrapper" id="myModal" role="dialog">
      <Box
        className="highlight-backdrop"
        data-testid="highlight-backdrop-close"
        onClick={this.closePopup}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 2,
        }}
      />

      <Box
        className="highlight-popup"
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '240px', // Default width
          height: '380px',
          bgcolor: '#1d1d1d',
          border: '1px solid #7b7b7b',
          padding: '20px',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          borderRadius: '5px',
          color: '#fff2e3',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gridGap: '140px', // Default gap
          fontFamily: 'inter, sans-serif',
          fontSize: '14px',
          zIndex: 9999,
          ...(this.state.image && {
            width: '500px', // Adjusted width when this.state.image is true
            gap: '5px', // Adjusted gap when this.state.image is true
          }),
        }}
        style={{
          transform: 'translate(-50%, -50%)', // Apply transform directly in style
        }}
      >
        <Button
          id="close-btn-highlight"
          className="story-upload-btn closebtn"
          onClick={() => this.closePopup()}
          sx={{
            ...styles['story-upload-btn'],
            ...styles.closebtn,
          }}
        >

          <StyledImage
            src={closeIcon}
            alt="close-icon"
            className="story-upload-icon"
          />
        </Button>
        <Box
          className={`highlight-popup-heading ${this.state.image ? "highlight-popup-heading-width" : ""
            }`}
            sx={{...styles['highlight-popup-heading'],
              ...this.isImageActive()
            }}
        >
          <div className="highlight-popup-heading-main">
            Add work in progress
          </div>

          {this.state.image && (
            <div>
              <Button
                className="discardBtn"
                data-testid="discard-btn"
                onClick={this.handleDiscardHighlight}
                sx={{...styles.discardBtn}}
              >
                Discard
              </Button>
              <Button
                className="saveProgressBtn"
                data-testid="save-Progress-btn"
                onClick={this.handleCroppedImage}
                sx={{...styles.saveProgressBtn}}
              >
                Share Progress
              </Button>
            </div>
          )}
        </Box>

        <div
          className="image-uploader"
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
        >
          {!this.state.image && (
            <>
              <Box className="highlight-popup-body"
              sx={{...styles['highlight-popup-body']}}>
                <div>
                  <StyledImage
                    src={uploadIcon}
                    alt="upload-icon"
                    className="story-upload-icon"
                  />
                  Drop your work here
                </div>
                <div>or</div>
                <label htmlFor="file-input" className="upload-label">
                  <div className="uploadtn">Upload files</div>{" "}
                </label>
              </Box>

              <input
                type="file"
                id="file-input"
                data-testid="inputFileId"
                accept="image/*"
                onChange={this.handleFileInputChange}
                className="image-upload-input"
                style={styles.displayNone}
              />
            </>
          )}

          {this.state.image && (
            <>
              <Box className="crop-container w-500 h-200">
                <>
                  <Box className="cornerBox cornerBox1" 
                  sx={{...styles.cornerBox,
                    ...styles.cornerBox1}}
                    />
                  <Box className="cornerBox cornerBox2"
                  sx={{...styles.cornerBox,
                    ...styles.cornerBox2}} />
                  <Box className="cornerBox cornerBox3"
                  sx={{...styles.cornerBox,
                    ...styles.cornerBox3}} />
                  <Box className="cornerBox cornerBox4" 
                  sx={{...styles.cornerBox,
                    ...styles.cornerBox4}}/>
                </>
                <Cropper
                  image={URL.createObjectURL(this.state.image)}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={2 / 3}
                  onCropChange={this.handleCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.handleZoomChange}
                />
              </Box>
              <Box className="controls" sx={{...styles.controls}}>
                <img
                  src={imgIcon}
                  alt="img Icon small"
                  className="imgIconFirst"
                  style={{...styles.imgIconFirst}}
                />
                <input
                  type="range"
                  data-test-id="zoom-input"
                  value={this.state.zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={this.handleZoomChangeFromSlider}
                  className="zoom-range"
                />
                <img
                  src={imgIcon}
                  alt="img Icon large"
                  className="imgIconlast"
                  style={{...styles.imgIconLast}}
                />
              </Box>
            </>
          )}
        </div>
      </Box>
    </Box>
  )
}

  renderHighlightsList = () =>{
    return this.state.clickedHighlightIndex == null && <HighlightList
      viewedHighlightIds={this.state.viewedHighlightIds}
      logindUserUnViewedIds = {this.state.logindUserUnViewedIds}
      newHighlightPresent= {this.state.newHighlightPresent}
      handleReturnSpliceValue = {this.handleReturnSpliceValue}
      domainUrl = {this.state.domainUrl}
      renderProfilePictureForOther = {this.renderProfilePictureForOther}
      renderProfilePictureWithDomain={this.renderProfilePictureWithDomain}
      allHighlightdata={this.state.allHighlightdata}
      userInfo={this.state.userInfo}
      renderProfilePictureWithoutHighlight={this.renderProfilePictureWithoutHighlight}
      handleViewHighlight={this.handleViewHighlight}
      checkIsViewed={this.checkIsViewed}
      uploadHighLightHandler={this.uploadHighLightHandler}
      itemsContainerRef={this.itemsContainerRef}
      scrollPosition={this.state.scrollPosition}
      userType={this.state.userType}
      handleScroll={this.handleScroll}
      loaderRef={this.loaderRef}
      userPhoto={this.state.userPhoto}
    />
  }

  isImageActive = () => {
    return (this.state.image)? styles['highlight-popup-heading-width']:{};
  }

  isElementVisible = (data: number) => {
    return ((this.state.clickedHighlightIndex != null ) && ((this.state.clickedHighlightIndex - data) >= 0))?{}:styles.opacityZero;
  }

  isBarSelected = (index: number) => {
    return (this.state.highlightState == index)? styles['bar_section_selected']:{};
  }

  activeIndex = () => {
    return this.state.clickedHighlightIndex != null ? this.state.clickedHighlightIndex: 0;
  }

  activeLikeDisLikeIcon = () => {
    return this.state.clickedHighlightIndex != null && this.state.allHighlightdata[this.state.clickedHighlightIndex]?.highlights[this.state.highlightState]?.data?.attributes
      ?.is_liked
      ? likedIcon
      : likeIcon;
  }

  render() {   

    return (
      <Box className="highlight-wrapper"
      sx={{
        ...styles['highlight-wrapper']
      }}
    > 
 <Box
    className="fixed-top"
    onClick={this.handleCloseHighlightOnNavbarClick}
    style={{
      position: 'fixed',
      width: '100%',
      zIndex: 999,
    }}
  >
    <NavigationMenu {...this.props} />
  </Box>
        {this.renderShowPopUp()}

        {this.renderHighlightsList()}

{this.state.clickedHighlightIndex != null && (
          <Box className="hightlight-viewer" 
          sx={{...styles['hightlight-viewer']}}>

            <Button
            sx={{...styles.closeViewHighlightBackdrop}}
              className="closeViewHighlightBackdrop"
              onClick={this.handleCloseViewHighlight}
            >
              <img src={arrowIcon} alt="arrow" />
              Highlights
            </Button>

            <Box
              className={`highlight-view-card sideCard displayNone`}
              sx={{
                ...styles['highlight-view-card'], 
                ...styles.sideCard,// Access using bracket notation
                ...styles.displayNone,
                ...this.isElementVisible(2),

              }}
            >
               <img  className="side_img"         
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '7px',
                }}       
               src={this.state.allHighlightdata[
                this.state.clickedHighlightIndex-2
              ]?.highlights[0]?.data?.attributes?.image_url} />

              <Box className="highlight-view-box-wrapper">
                <Box className="highlight-username-view"
                 sx={{...styles['highlight-username-view']}}>
                  <Box className="highlight-username-user_name dateContainer"
                   sx={{
                    ...styles.highlightUsername,
                    ...styles.dateContainer,
                  }}>
                   
                  </Box>
                </Box>
              </Box>
            </Box>
           
            <Box
              className={`highlight-view-card sideCard displayNoneNew`}
              sx={{
                ...styles['highlight-view-card'], 
                ...styles.sideCard,// Access using bracket notation
                ...this.isElementVisible(1),
              }}
            >
              <Box className="main_side_image_div">
                { this.state.clickedHighlightIndex - 1 >= 0 && <Typography id="main_side_image_div_date">{ this.formatDate(this.state.allHighlightdata[
                  this.state.clickedHighlightIndex - 1
                ]?.highlights[0]?.data?.attributes?.created_at)}</Typography> }
              <img className="side_img"
                src={this.state.allHighlightdata[
                  this.state.clickedHighlightIndex - 1
                ]?.highlights[0]?.data?.attributes?.image_url} />
                </Box>

 
              <Box className="highlight-view-box-wrapper">
                <Box className="highlight-username-view"
                 sx={{...styles['highlight-username-view']}}>
                  <Box className="highlight-username-user_name dateContainer"
                   sx={{
                    ...styles.highlightUsername,
                    ...styles.dateContainer,
                  }}>
                   
                  </Box>
                </Box>
              </Box>
            </Box>

            <Button
              className={`highlight-view-slider-button ${
                this.checkPrevHighLightIsThere()
              }`}
              sx={{
                cursor: 'pointer',
                zIndex: 2,
                '@media (max-width: 1400px)': {
                  width: '40px',
                },
                '@media (max-width: 1300px)': {
                  width: '40px',
                },
              }}
              onClick={this.viewHighlightScrollLeft}
            >
              <img src={rightArrow} alt="arrow" />
            </Button>
            <img className="highlight-view-card img_main" src={this.state.allHighlightdata[
                        this.state.clickedHighlightIndex
                      ]?.highlights[this.state.highlightState]?.data?.attributes?.image_url}
                      style={{
                        ...styles['highlight-view-card'], 
                        position: 'absolute',
                        borderRadius: '15px'
                      }}
                      />
            <Box
              key={
                this.state.allHighlightdata[this.state.clickedHighlightIndex]
                  ?.account_id
              }
              className="highlight-view-card"
              sx={{
                ...styles['highlight-view-card'], // Access using bracket notation
              }}
            >
              <Box className="highlight_top_view_bar" sx={{...styles['highlight_top_view_bar']}}>
                {this.state.allHighlightdata[
                  this.state.clickedHighlightIndex
                ]?.highlights?.map((highlight: HighlightItem, index: number) => (
                  <Box
                    key={index}
                    sx={{...this.isBarSelected(index),
                  ...styles['highlight_top_view_bar_section']}}

                    className={`highlight_top_view_bar_section`}
                  ></Box>
                ))}
              </Box>
              <Box className="highlight-view-box-wrapper" sx={{...styles.wrapper}}>
                <Box className="highlight-username-view"
                 sx={{...styles['highlight-username-view']}}>
                  {this.renderProfileAndHighlight()}
                  <Box>
                    <Box className="highlight-username-user_name"
                    sx={{
                      ...styles.highlightUsername,
                    }}>
                      {this.state.allHighlightdata[
                        this.state.clickedHighlightIndex
                      ]?.user_name
                        ? this.state.allHighlightdata[
                            this.state.clickedHighlightIndex
                          ]?.user_name
                        : ""}
                    </Box>
                    <Typography className="highlight-username-location" style={{marginTop:'11px',fontSize:'10px',fontFamily: 'Inter, sans-serif'}}> {
                      this.state.allHighlightdata[
                        this.state.clickedHighlightIndex
                      ]?.highlights[this.state.highlightState]?.data?.attributes
                        ?.location
                    } </Typography>
                  </Box>
                </Box>
                <Box sx={styles.likeIconWrapper}>
                  <img
                    src={
                      this.activeLikeDisLikeIcon()
                    }
                    onClick={() => {
                      this.handleLikeBtnClick(
                        this.state.allHighlightdata[
                          this.activeIndex()
                        ]?.highlights[this.state.highlightState].data.id,
                        this.state.allHighlightdata[
                          this.activeIndex()
                        ]?.highlights[this.state.highlightState].data.attributes
                          .is_liked
                      );
                    }}
                  />
                  <span>
                    {
                      this.state.allHighlightdata[
                        this.state.clickedHighlightIndex
                      ]?.highlights[this.state.highlightState]?.data?.attributes
                        ?.likes
                    }
                  </span>
                </Box>

                <Box sx={styles.viewIconWrapper}>
                  <img src={viewIcon}
                  style={styles.viewIconImg} />
                  <span>
                    {
                      this.state.allHighlightdata[
                        this.state.clickedHighlightIndex
                      ]?.highlights[this.state.highlightState]?.data?.attributes
                        ?.views
                    }
                  </span>
                </Box>
              </Box>
              <Box className="highlight-control-button" sx={{ height: '85%' }}>
                <Button onClick={this.showPrevHiglight}
                  sx={{
                    width: '50%',
                    height: '100%',
                    background: 'transparent',
                    opacity: 0,
                    zIndex: 99,
                  }}
                ></Button>
                <Button onClick={this.showNextHiglight}
                  sx={{
                    width: '50%',
                    height: '100%',
                    background: 'transparent',
                    opacity: 0,
                    zIndex: 99,
                  }}
                ></Button>
              </Box>
            </Box>
            <Button
              className={`highlight-view-slider-button rightArrow ${
                this.checkNextHighLightIsThere()
              } `}
              sx={{
                cursor: 'pointer',
                zIndex: 2,      
                '@media (max-width: 1400px)': {
                  width: '40px',
                },
                '@media (max-width: 1300px)': {
                  width: '40px',
                },
                ...styles.rightArrow,
              }}
              onClick={this.viewHighlightScrollRight}
            >
              <img src={leftArrowNew} alt="arrow"/>
            </Button>

            <Box
              className={`highlight-view-card sideCard displayNoneNew`}
              sx={{
                ...styles['highlight-view-card'], 
                ...styles.sideCard,// Access using bracket notation
                ...( this.state.clickedHighlightIndex + 1 <=
                  this.state.allHighlightdata.length - 1)?{}:styles.opacityZero,
              }}
            >
              <Box className="main_side_image_div">
                { this.state.clickedHighlightIndex + 1 <=
                  this.state.allHighlightdata.length - 1 &&  <Typography id="main_side_image_div_date">{ this.formatDate(this.state.allHighlightdata[
                  this.state.clickedHighlightIndex + 1
                ]?.highlights[0]?.data?.attributes?.created_at)}</Typography>}
              <img className="side_img"
                src={this.state.allHighlightdata[
                  this.state.clickedHighlightIndex + 1
                ]?.highlights[0]?.data?.attributes?.image_url} />
              </Box>
              <Box className="highlight-view-box-wrapper"
              sx={{
                  display: 'flex',
                  marginTop: '25px',
                  fontSize: '12px',
                  justifyContent: 'flex-end',
                  marginLeft: 'auto',
                  color: '#fff',
                  gridGap: '3px',
                  fontFamily: 'Inter, sans-serif'
              }}>
                <Box
                  className="highlight-username-view"
                  sx={{...styles['highlight-username-view']}}
                >
                  <Box className="highlight-username-user_name dateContainer"
                   sx={{
                    ...styles.highlightUsername,
                    ...styles.dateContainer,
                  }}>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              className={`highlight-view-card sideCard displayNone`}
              sx={{
                ...styles['highlight-view-card'], 
                ...styles.sideCard,// Access using bracket notation
                ...( this.state.clickedHighlightIndex + 2 <=
                  this.state.allHighlightdata.length - 1)?{}:styles.opacityZero,
                ...styles.displayNone,
              }}
            >
              <img
                className="side_img"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '7px',
                }}           
                src={this.state.allHighlightdata[
                this.state.clickedHighlightIndex+2
              ]?.highlights[0]?.data?.attributes?.image_url} />
              <Box className="highlight-view-box-wrapper">
                <Box className="highlight-username-view"
                sx={{...styles['highlight-username-view']}}>
                  <Box className="highlight-username-user_name dateContainer"
                   sx={{
                    ...styles.highlightUsername,
                    ...styles.dateContainer,
                  }}>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        <ToastMSG
          close={this.handleCloseToast}
          open={this.state.openToast}
          message={this.state.errMessage}
          action={this.state.action}
        />
       {this.renderContentBasedOnUserRole()}

      </Box>
    );
  }
}

export default LandingPage;

// Customizable Area End