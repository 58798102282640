import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const navigation = require("react-navigation");

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

export interface ResponseForLinkedinData {
  user_data: {
    sub: string;
    email_verified: boolean;
    name: string;
    locale: {
      country: string;
      language: string;
    };
    given_name: string;
    family_name: string;
    email: string;   

    picture?: string; }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  accountData: { [key: string]: string };
  linkedinData:ResponseForLinkedinData | null;
  selectUserType: string;
  checkCondition: boolean;
  isLinkedInData:boolean;
  userErrorMessage: string;
  isSubmit: boolean;
  acceptConditionError: string;
  open: boolean,
  message: string,
  action: string,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class PostEmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  socialRegistrationApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      accountData: {},
      selectUserType: "1",
      checkCondition: false,
      isLinkedInData:false,
      linkedinData:null,
      userErrorMessage: "",
      isSubmit: false,
      acceptConditionError: "",
      open: false,
      message: "",
      action: "",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start 
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.socialRegistrationApiCallID) {
        if (responseJson.data) {
          await setStorageData('userInfo',JSON.stringify(responseJson));
          await setStorageData("isLinkedinData", JSON.stringify(false))
          const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
          );
          message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          this.send(message);
        }
        else {
          this.setState({
            open: true,
            action: "danger",
            message:Object.values(responseJson.errors[0]).join()
          })
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidMount = async () => {
    let googleData = await getStorageData("googleData");
    let linkedInData = await getStorageData("linkedinData");
    let isLinkedInData = await getStorageData("isLinkedinData")
    if(isLinkedInData){
      this.setState({ accountData: JSON.parse(linkedInData), isLinkedInData : true  });
    }else{
      this.setState({ accountData: JSON.parse(googleData) });
    }
  };

  handleUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      accountData: {
        ...this.state.accountData,
        given_name: event.target.value,
      },
    });
  };

  handleProfileSelect = (value: string) => {
    this.setState({ selectUserType: value });
  };

  handleAcceptConditions = () => {
    this.setState({ checkCondition: !this.state.checkCondition });
  };

  postSocialSignUp = async () => {
    if (
      this.state.accountData.given_name === "" ||
      this.state.accountData.given_name.length < 3
    ) {
      this.setState({ userErrorMessage: configJSON.userNameErrorMsg });
      return;
    }
    if (this.state.checkCondition === false) {
      this.setState({ isSubmit: true });
    } else {

      const jsonData = {
        data: {
          type: "social_account",
          attributes: {
            email: this.state.accountData.email,
            role_id: this.state.selectUserType,
            unique_auth_id: this.state.accountData.sub,
            first_name: this.state.accountData.name,
            user_name: this.state.accountData.given_name,
          },
          profile: {
            image: this.state.accountData.picture || "",
          }
        }
      };

      const header = {
        "Content-Type":configJSON.validationApiContentType
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.socialRegistrationApiCallID = requestMessage.messageId;
        requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.googleLoginApi
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(jsonData)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ isSubmit: true });
    }
  };

  handleCloseToast = () => {
    this.setState({
      open: false,
      message: ""
    })
  }
  // Customizable Area End
}