import React from "react";

// Customizable Area Start
import {
  Container,
  Box,Button,
  Input,Typography,
  InputAdornment,IconButton,
  Checkbox,
  withStyles,TextField,
  Grid,InputLabel,
  Avatar, 
  Snackbar
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { BackgroundImage, 
  Logo, 
  GoogleLogo, 
  LinkedInLogo, CloseBtnIcon, SuccessIcon  } from "./assets";
import Divider from '@material-ui/core/Divider';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin } from '@react-oauth/google';

const CustomTextField = withStyles({
  root: {
      '& .MuiOutlinedInput-root': {
          '& fieldset': {
              borderColor: '#252525',
              borderRadius: "8px"
          },
          '&:hover fieldset': {
              borderColor: '#252525',
              borderRadius: "8px"
          },
          '&.Mui-focused fieldset': {
              borderColor: '#252525',
              borderRadius: "8px"
          },
      },
      '& p.MuiFormHelperText-root': {
          fontSize: "14px",
          lineHeight: "16.94px",
          color: "#FF7878",
          fontFamily: "Inter, sans-serif"
      }
  },
})(TextField);

const custombox = {
  mainContainer: {
      backgroundImage: `url(${BackgroundImage})` ,
      backgroundSize: "100vw",
      backgroundRepeatX: "no-repeat",
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      
  },
  formContainer:{
  },
      linkedInImg:{
        height:40,
        width:40
      },
  root: {
    opacity: "0.92",
    background: '#111111',
    height: "auto",
    borderRadius: "24.11px",
    overflow: "unset",
    width: "639px",
    marginTop: "30px",
    marginBottom: "30px"
},
  inputLabel: {
    color: '#6C6C6C',
    fontFamily:"Inter, sans-serif",
    lineHeight: "16.94px",
    marginBottom: "12.65px",
    fontSize: "14px"
},
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required'),
});

import ToastMSG from "../../../components/src/ToastMSG.web";
import { styled } from "@material-ui/styles";

const LoginWrapper = styled(IconButton)({
   padding: 0,
   margin: 0 , 
   marginRight: "15px"
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

 handleLoginClicked() {
  this.setState({ isSubmit: true});
 }

 handleSubmitForm=()=>{
  this.doEmailLogIn()
}
formData(e: any, setterFnc: any){
  this.setState({...this.state, [e?.target?.name]:[e?.target?.value], isSubmit: false})
  setterFnc(e)
}
handleClose(){
  this.setState({openToast: false, toastMessage: ""});
}
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
    
      <ThemeProvider theme={theme}>
        <Box sx={custombox.mainContainer} >
          <Box sx={custombox.formContainer}>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          data-test-id="formikform"
          validationSchema={validationSchema}
          onSubmit={this.handleSubmitForm}
        >
          {({ values, errors, handleSubmit, handleChange }) => (
          <Form data-test-id="formikformData" onSubmit={handleSubmit} noValidate autoComplete="off" style={{ margin: "50px" }}>
          <Box style={custombox.root}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "32.64px 1.16px 0 0",
              borderRadius:"24.48px",
            }}
          >
            <Grid item xs={6} style={{ display: "flex", justifyContent: "center", width:"248.84px", height:"64.58px", marginTop:"41px", flexBasis:"13%"}}>
              <img src={Logo} style={{width:"248.84px", height:"64.58px"}} />
            </Grid>
            <Grid item xs={6} style={{ display: "flex", justifyContent: "center", height:"41.8px", width: "279.73px" }}>
              <Typography style={{ fontSize: "27.97px", color: "#fff2e3", marginTop: "33.1px", lineHeight: "33.85px", fontFamily: "Inter-SemiBold, sans-serif" }}>Welcome back</Typography>
            </Grid>
 
            <Box sx={{ width: "439px", marginTop: "40.57px"}}>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column"}}>
              <InputLabel style={custombox.inputLabel}>Email</InputLabel>
                 <CustomTextField
                    data-test-id={"txtInputEmail"}
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={values.email}
                    required
                    onChange={(e)=>this.formData(e, handleChange)}
                    inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', fontSize: "14px", color: '#fff2e3' } }}
                    helperText={this.state.isSubmit && errors.email}
                  />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", flexDirection: "column", marginTop: "21.31px" }}>
              <InputLabel style={custombox.inputLabel}>Password</InputLabel>
                <CustomTextField
                  data-test-id={"txtInputPassword"}
                  fullWidth
                  type="password"
                  name="password"
                  variant='outlined'
                  required
                  value={values.password}
                  onChange={(e)=>this.formData(e, handleChange)}
                  inputProps={{ style: { fontFamily: 'Inter-SemiBold, sans-serif', fontSize: "14px", color: '#fff2e3' } }}
                  helperText={this.state.isSubmit && errors.password}
                />
              </Grid>
              <Box
                sx={{
                  marginTop: "12.56px",
                  display:"flex",
                  justifyContent:"flex-end",
                }}
              >
                <span
                  data-test-id={"btnForgotPassword"}
                  onClick={() => this.goToForgotPassword()}
                  style={{cursor:"pointer", color: "#FFF2E3", marginRight: "5.51px", fontSize: "14px", lineHeight: "16.94px", fontFamily:"Inter, sans-serif"}}
                >Forgot Password ?</span>
              </Box>
            </Box>
          </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "25px 0px",
                }}
              >
                <Button
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  onClick={() => this.handleLoginClicked()}
                  type="submit"
                  style={{ backgroundColor: '#FFF2E3', width: "114px", height: "44px"}}
                >
                  <span style={{ textTransform: "none", fontSize:"16px", letterSpacing: "0.38px", lineHeight: "24px", color: "#121212", fontFamily:"Inter-SemiBold, sans-serif" }}>
                    Sign in
                  </span>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "15px 0 0",
                  color:"#fff2e3",
                  fontFamily:"Inter, sans-serif",
                  fontSize: "14px",
                  lineHeight: "16.94px"
                }}
              >
                or sign in using
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  margin: "20px 0 0",
                  justifyContent:"center"
                }}
              >
                <LoginWrapper id="btnSocialLogin" data-test-id="btnSocialLogin" onClick={() => this.goToSocialLogin()}>
                        <GoogleLogin
                          type="icon"
                          shape="circle"
                          data-testId="googleSignInId"
                          onSuccess={(credentialResponse) => this.onSuccessResponse(credentialResponse.credential as string)}
                          onError={this.onFailure}
                        />
                </LoginWrapper>
                <LinkedIn
                                                        clientId="86c8f7ce9xo82s"
                                                        scope="profile openid email"
                                                        data-testId= "linkedInSignUpId"
                                                        redirectUri={`${window.location.origin}/linkedin`}
                                                        onSuccess={this.onSuccess}
                                                        onError={this.onError}
                                                    >
                                                        {({ linkedInLogin }: { linkedInLogin: () => void }) => (
                                                        <img
                                                            onClick={linkedInLogin}
                                                            src={LinkedInLogo}
                                                            alt="Sign in with Linked In"
                                                            style={{...custombox.linkedInImg}}
                                                        />
                                                        )}
                                                    </LinkedIn>
              </Box>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <Divider style={{ border: '1px solid #fff2e342', height: '0px', width: "100%", margin: '0px', marginTop:"41px"}} />
                <Box style={{ color: '#FFF2E3', margin: '19.73px 0 10.47px', height: "41.8px", textAlign: "center", lineHeight: "16.94px", fontSize: "14px", fontFamily:"Inter, sans-serif" }}>
                  Don't have an account? <span data-test-id={"btnSignUp"} style={{ textDecoration:"underline", textUnderlineOffset: "3px", fontFamily:"Inter-SemiBold, sans-serif", cursor:"pointer" }} onClick={()=>this.navigateEmailRegistration()}>Sign up</span>
                </Box>
              </Grid>
            </Box>
          </Form>
          )}
          </Formik>
          </Box>
          </Box>
          <Box>
            <ToastMSG data-testId= "toastMessageId" close={() => this.handleClose()} open={this.state.openToast} message={this.state.toastMessage} action={this.state.toastHeading} />
          </Box>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
