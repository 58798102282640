// Customizable Area Start
export const customStyle = (theme) => ({
    "& .main_grid_container":{
       
        margin: "50px auto",
        // backgroundColor:'red'
    }, "& .grid_card":{
        width:"378px", margin:"15px auto",maxHeight: "310px", minWidth:'300px'
    },"& .card_container":{
        // width: "378px", maxHeight: "310px", margin:'10px' 
        // backgroundColor:'red',
        width:"100%", height:'100%'
    }, "& .card_image":{
        height:"260px", width:"100%", borderRadius:10, cursor: "pointer"
    }, "& .title_container":{
        display: "flex", justifyContent: "space-between",marginTop:"10px"
    },
    '&.discover-project-container .card-label, &.discover-projectDetails-container .card-label': {
        fontFamily: "Inter-SemiBold, sans-serif",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.38px",
        color: "#fff2e3",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    '&.discover-project-container .card-text, &.discover-projectDetails-container .card-text': {
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        lineHeight: "24px",
        letterSpacing: "0.38px",
        color: "#fff2e3",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    '&.discover-project-container .manage-space, &.discover-project-container .manage-space-feature,&.discover-projectDetails-container .manage-space,&.discover-projectDetails-container .manage-soace-feature': {
        display: "flex",
        alignItems: "center"
    },
    '&.discover-project-container .manage-space-feature,&.discover-projectDetails-container .manage-space-feature': {
        gap: "2px"
    },
    '&.discover-project-container .manage-space,&.discover-projectDetails-container .manage-space': {
        gap: "8px"
    },
    '&.discover-project-container .eyeIcon,&.discover-projectDetails-container .eyeIcon': {
        width: "24.2px",
        height: "17.6px"
    },
    '&.discover-project-container .likeIcon,&.discover-projectDetails-container .likeIcon': {
        width: "18.7px",
        height: "16.5px"
    },
    '&.discover-project-container .featureIcon,&.discover-projectDetails-container .featureIcon': {
        width: "22px",
        height: "22px"
    },
    '&.discover-project-container .tags-container::-webkit-scrollbar,&.discover-projectDetails-container .tags-container::-webkit-scrollbar': {
        width: 0,
        height: 0
    },
    '& .tags': {
        color: "#fff2e3",
        height: "37px",
        fontSize: "12px !important",
        lineHeight: "14.52px",
        textAlign: "center",
        verticalAlign: "middle",
        border: "0.5px solid #fff2e3",
        backgroundColor: "transparent",
        borderRadius: "18.2px",
        fontFamily: "Inter, sans-serif !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "22px",
        paddingRight: "22px",
        whiteSpace: "nowrap",
        cursor: "pointer"
    },
    '& .tags-active': {
        borderWidth: "1px",
        fontFamily: "Inter-SemiBold, sans-serif !important",
        backgroundColor: "#323232",
        borderRadius: "21px",
        columnGap: "10px"
    },
    [theme.breakpoints.between(1280, 1369)]: {
        '&.discover-project-container .card-style, &.discover-projectDetails-container .card-style': {
            width: "264px",
            height: "182.4px"
        },
        '&.discover-project-container .project-title,&.discover-projectDetails-container .project-title': {
            maxWidth: "100px",
            paddingRight: "6px"
        },
        '&.discover-project-container .manage-gap,&.discover-projectDetails-container .manage-gap': {
            columnGap: "10px"
        },
        '&.discover-project-container .title-container,&.discover-projectDetails-container .title-container': {
            width: "264px !important"
        },
        '&.discover-project-container .card-label,&.discover-projectDetails-container .card-label': {
            fontSize: "12px"
        },
        '&.discover-project-container .card-text,&.discover-projectDetails-container .card-text': {
            fontSize: "10px"
        },
        '&.discover-project-container .card-label, &.discover-project-container .card-text,&.discover-projectDetails-container .card-label, &.discover-projectDetails-container .card-text': {
            lineHeight: "16px"
        }
    },
    [theme.breakpoints.between(1400, 1460)]: {
        '&.discover-project-container .manage-gap,&.discover-projectDetails-container .manage-gap': {
            columnGap: "12px"
        },
        '&.discover-project-container .card-container,&.discover-projectDetails-container .card-container': {
            marginLeft: "32px !important"
        }
    },
    [theme.breakpoints.between(1461, 1790)]: {
        '&.discover-project-container .card-style,&.discover-projectDetails-container .card-style': {
            width: "302.4px",
            height: "208px"
        },
        '&.discover-project-container .project-title,&.discover-projectDetails-container .project-title': {
            maxWidth: "120px !important",
            paddingRight: "8px !important"
        },
        '&.discover-project-container .manage-gap,&.discover-projectDetails-container .manage-gap': {
            columnGap: "14px"
        },
        '&.discover-project-container .title-container,&.discover-projectDetails-container .title-container': {
            width: "302.4px !important"
        },
        '&.discover-project-container .card-text,&.discover-projectDetails-container .card-text': {
            fontSize: "12px"
        },
        '&.discover-project-container .card-container,&.discover-projectDetails-container .card-container': {
            columnGap: "32px",
            marginLeft: "32px !important"
        }
    },
    [theme.breakpoints.between(1500, 1560)]: {
        '&.discover-project-container .card-container,&.discover-projectDetails-container .card-container': {
            marginLeft: "55px !important"
        }
    },
    [theme.breakpoints.between(1561, 1660)]: {
        '&.discover-project-container .card-style,&.discover-projectDetails-container .card-style': {
            width: "320px",
            height: "220.8px"
        },
        '&.discover-project-container .project-title,&.discover-projectDetails-container .project-title': {
            maxWidth: "140px !important",
            paddingRight: "8px !important"
        },
        '&.discover-project-container .manage-gap,&.discover-projectDetails-container .manage-gap': {
            columnGap: "12px"
        },
        '&.discover-project-container .title-container,&.discover-projectDetails-container .title-container': {
            width: "320px !important"
        },
        '&.discover-project-container .card-text,&.discover-projectDetails-container .card-text': {
            fontSize: "12px"
        },
        '&.discover-project-container .card-container,&.discover-projectDetails-container .card-container': {
            columnGap: "46px"
        }
    },
    [theme.breakpoints.between(1661, 1800)]: {
        '&.discover-project-container .card-style,&.discover-projectDetails-container .card-style': {
            width: "324.8px",
            height: "224px"
        },
        '&.discover-project-container .project-title,&.discover-projectDetails-container .project-title': {
            maxWidth: "135px !important",
            paddingRight: "8px !important"
        },
        '&.discover-project-container .manage-gap,&.discover-projectDetails-container .manage-gap': {
            columnGap: "16px"
        },
        '&.discover-project-container .title-container,&.discover-projectDetails-container .title-container': {
            width: "324.8px !important"
        },
        '&.discover-project-container .card-text,&.discover-projectDetails-container .card-text': {
            fontSize: "12px"
        },
        '&.discover-project-container .card-container,&.discover-projectDetails-container .card-container': {
            columnGap: "46px"
        }
    },
    [theme.breakpoints.between(1801, 1920)]: {
        '&.discover-project-container .card-style,&.discover-projectDetails-container .card-style': {
            width: "352px",
            height: "241.6px"
        },
        '&.discover-project-container .project-title,&.discover-projectDetails-container .project-title': {
            maxWidth: "157px !important",
            paddingRight: "10px !important"
        },
        '&.discover-project-container .manage-gap,&.discover-projectDetails-container .manage-gap': {
            columnGap: "19px"
        },
        '&.discover-project-container .title-container,&.discover-projectDetails-container .title-container': {
            width: "352px !important"
        }
    }
});
// Customizable Area End