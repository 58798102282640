import axios from 'axios';
import storage from "../../framework/src/StorageProvider.web";
export async function getBase64Image(url: string, loadedData?: (percentage: number) => void) {
    try {
      const response = await fetch(url);
      const contentLength = parseInt(response.headers.get('Content-Length') || '0', 10);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
        reader.onprogress = (event) => {
          if (event.lengthComputable && loadedData) {
            const percentLoaded = (event.loaded / contentLength) * 100;
            loadedData(percentLoaded);
          }
        };
      });
    } catch (error) {
      console.error('Error converting image to base64:', error);
      return null;
    }
};  

export async function convertImageUrlToBase64(objects: any[]) {
    try {
      const updatedObjects = [];
      for (const obj of objects) {
        const updatedObj = { ...obj, image_url: obj.data.attributes.image_url };
        updatedObjects.push(updatedObj);
      }
      return updatedObjects;
    } catch (error) {
      console.error("Error converting image URLs to Base64:", error);
      throw error;
    }
}

export async function uploadImage(croppedImage:any, dataobj: any, url: string, arrData: any,userInfo:any,tokenData:string) {
    const token = tokenData;
    const headers = { 'Content-Type': 'multipart/form-data' };
    if (!dataobj) return;
    const file = new File([croppedImage], dataobj.name, { type: dataobj.type });
    const body = { token, highlight: { image: file } };
    return axios.post(url, body, { headers }).then(async (res) => {
        const dataRes= {
          data: {
              id: res.data.highlight.data.id,
              type: "highlight",
              image_url: res.data.highlight.data.attributes.image_url,
              attributes: res.data.highlight.data.attributes
          }
      };

     let accountData= {
        account_id: null,
        user_name: userInfo.user_name,
        profile_image: userInfo.profile_image,
        background_color:userInfo.background_color,
        "highlights": [
          dataRes
        ]
    }
        return {
          errMessage: "Highlight created successfully",
          openToast: true,
          action: "success",
          allHighlightdata:
            arrData.filter((item: any) => item.user_name === userInfo.user_name)
              .length == 0
              ? [accountData, ...arrData]
              : arrData.map((item: any) => {
                  if (item.user_name === userInfo.user_name) {
                    item.highlights.push(dataRes);
                  }
                  return item;
              })
        };
    }).catch(error => ({
        errMessage: error,
        openToast: true,
        action: "success"
    }));
}

export async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0) {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = imageSrc;
    await new Promise((resolve, reject) => {
      image.onload = () => resolve(image);
      image.onerror = reject;
    });
    const canvas = document.createElement("canvas");
    const ctx:any = canvas.getContext("2d");
    const safeArea = 2 * Math.sqrt(2) * (Math.max(image.width, image.height) / 2);
    canvas.width = canvas.height = safeArea;
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.translate(-safeArea / 2, -safeArea / 2);
    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    ctx.putImageData(data, Math.round(-safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(-safeArea / 2 + image.height * 0.5 - pixelCrop.y));
    return new Promise(resolve => canvas.toBlob(resolve, "image/jpeg"));
}

export function fileToDataURL(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
}

export async function compressedImageData(file: File, maxWidth?: number, maxHeight?: number, quality?: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        if (!event.target || !(event.target instanceof FileReader)) {
          reject(new Error("Failed to read file"));
          return;
        }
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > (maxWidth || width)) {
              height *= (maxWidth || width) / width;
              width = (maxWidth || width);
            }
          } else {
            if (height > (maxHeight || height)) {
              width *= (maxHeight || height) / height;
              height = (maxHeight || height);
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          if (!ctx) {
            reject(new Error("Failed to get 2D context"));
            return;
          }
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            if (!blob) {
              reject(new Error("Failed to create Blob"));
              return;
            }
            const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
            resolve(compressedFile);
          }, 'image/jpeg', quality);
        };
        img.onerror = () => {
          reject(new Error("Failed to load image"));
        };
        img.src = event.target.result as string;
      };
      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
      reader.readAsDataURL(file);
    });
  }

  export async function getPlaceSuggestions(query:string) {
    if (query.trim() !== '' && query.trim().length >= 2) {
      try {
        const response = await fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&types=(cities)&components=country:in&key=AIzaSyAr4npdtKgtN4ghvTUj_DeOCmCGnHrq7K0`);
        const data = await response.json();
        return data.predictions.map((item: any) => item.structured_formatting.main_text);
      } catch(error) {
        throw error;
      }
    } else {
      return [];
    }
  }

export async function getCroppedImgData(imageSrc: string, crop: any) {
  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
  const canvas = document.createElement('canvas');
  const ctx:any = canvas.getContext('2d');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  ctx.drawImage(
    image,
    crop.x*scaleX,
    crop.y*scaleY,
    crop.width*scaleX,
    crop.height*scaleY,
    0,
    0,
    crop.width,
    crop.height
  );
  return new Promise(resolve => {
    canvas.toBlob(resolve, 'image/jpeg');
  });
};
  
  export const hasHighlightData = (data:any) => {
    return data && data.length > 0;
  };
  

  export const isDesigner = (type:any) => {
    return type === "designer";
  };
  
  export const isDifferentUser = (data:any, userInfo:any) => {
    return data[0]?.user_name !== userInfo?.user_name;
  };
  
 export const shouldShowHighlights = (allHighlightdata:any, userType:any, userInfo:any) => {
    return (
      (isDesigner(userType) && hasHighlightData(allHighlightdata) && isDifferentUser(allHighlightdata, userInfo)) ||
      !hasHighlightData(allHighlightdata)
    );
  };

  export const getToken=async ()=>{
    return storage.get("userInfo").then(res => {
      let storeData = JSON.parse(res);
      if (storeData && storeData.meta) {
        return storeData.meta.token   
      }
    })
  }

  export const getUserProfile=async ()=>{
    return storage.get("userProfile").then(res => {
      let storeData = JSON.parse(res);
      return storeData
    })
  } 
  

  export const customStylesSelect = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#171717",
      width: "900px",
      border: "none",
      color: "#fff",
      boxShadow: null,
      "&:hover": {
        borderColor: "#555",
      },
    }),
    multiValue: (styles: any, { data }: any) => {
      return {
        ...styles,
        textDecoration:"underline",
        backgroundColor: "#171717",
      };
    },
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: "#fff",
      fontSize: "16px",
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#171717",
      color: "#fff",
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '-ms-overflow-style': 'none',  
      'scrollbar-width': 'none',  
    }),
    menuList: (provided:any) => ({
      ...provided,
      padding: 0,
      '::-webkit-scrollbar': {
        width: '0px',
        background: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        background: 'transparent',
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',  
    }),
    clearIndicator: (provided:any) => ({
      ...provided,
      display: "none"
    }),
    input: (provided:any) => ({
      ...provided,
      fontSize: '16px',
      color: "#fff",
   
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#555"
        : state.isFocused
        ? "#333"
        : "#171717",
      color: "#fff",
      "&:active": {
        backgroundColor: state.isSelected ? "#555" : "#333",
      },
    }),
    multiValueRemove: (base:any, state:any) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base:any, state:any) => ({
      ...base,
      display: 'none',
    }),
    indicatorSeparator: (base:any, state:any) => ({
      ...base,
      display: 'none',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#fff",
      textDecoration:"underline",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#ccc",
    }),
  };

const isValidInstagramUrl=(url:any)=>{
  const re = /^https:\/\/www\.instagram\.com\/[a-zA-Z0-9._]+\/?$/;
  return re.test(url);
}

function isValidLinkedInUrl(url:any) {
  const regex = /^\s*(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]{1,100}\s*$/i;
  return regex.test(url);
}

function isValidWebsiteUrl(url:any) {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;%=]*)?$/i;
  return regex.test(url);
}

function isValidLocation(location:any) {
  const regex = /^[a-zA-Z\s,.-]{2,100}$/;
  return regex.test(location);
}

function isValidName(name:any) {
  const regex = /^[a-zA-Z\s,.'-]{1,100}$/;
  return regex.test(name);
}

function isValidDribbbleUrl(url:any) {
  const regex = /^\s*(https?:\/\/)?(www\.)?dribbble\.com\/[a-zA-Z0-9-]{1,100}\s*$/i;
  return regex.test(url);
}

 export const setErrorProfileValue=(field:any,editProfileData:any)=>{
    let error=''
    switch(field){
      case 'name':
        if (!editProfileData.name?.trim()) {
         error = 'Name cannot be empty';
       }
       else if(!isValidName(editProfileData.name)){
        error = 'Please enter a valid name';
       }
        break;
        case 'companyName' :
          if(!editProfileData.companyName){
            error = 'Company name cannot be empty';
          }
          break;
      case 'website':
        if (!isValidWebsiteUrl(editProfileData.website)&&editProfileData.website) {
          error = 'Please enter a valid website URL';
        }
        break;
      case 'insta':
        if (!isValidInstagramUrl(editProfileData.insta)&&editProfileData.insta) {
          error = 'Please enter a valid Instagram ID';
        }
        break;
        case 'location':
        if (!isValidName(editProfileData.location)&&editProfileData.location.length) {
          error = 'Please enter a valid location';
        }
        break;
      case 'dribble':
        if (!isValidDribbbleUrl(editProfileData.dribble)&&editProfileData.dribble) {
          error = 'Please enter a valid dribble profile URL';
        }
        break;
      case 'linkedin':
        if (!isValidLinkedInUrl(editProfileData.linkedin)&&editProfileData.linkedin) {
          error = 'Please enter a valid LinkedIn profile URL';
        }
        break;
        case 'about':
          if (!editProfileData.about?.trim()) {
            error = '';
          }
          default:
        break;
  }
  return error
  }

  export const setErrorEditProfileEducationSection = (
    field: any,
    editProfileData: any,
    index:number
  ) => {
    let error = "";
    switch (field) {
      case "qualification":
        if (!editProfileData.education[index].qualification?.trim()) {
          error = "";
        }
        break;
      case "year_from":
        if (!editProfileData.education[index].year_from?.trim()) {
          error = "";
        }
        else if(editProfileData.education[index].year_from>editProfileData.education[index].year_to){
          error="Started year cannot be greater than ended year"
        }
        else if(editProfileData.education[index].year_from>editProfileData.education[index].year_to){
          error="Started year cannot be greater than ended year"
        }
        break;
      case "year_to":
        if (!editProfileData.education[index].year_to?.trim()) {
          error = "";
        }
        else if(editProfileData.education[index].year_from>editProfileData.education[index].year_to){
          error="Ended year cannot be less than started year"
        }
        else if(editProfileData.education[index].year_from>editProfileData.education[index].year_to){
          error="Ended year cannot be less than started year"
        }
        break;
      case "description":
        if (!isValidName(editProfileData.education[index].description)&&editProfileData.education[index].description) {
          error = "Please enter a valid course";
        }
        break;
      default:
        break;
    }
  
    return error
  };


  export const checkErrorEditProfileUser=(index:any,editProfileError:any,editProfileData:any)=>{
    if(editProfileError.education[index]?.year_from||editProfileError.education[index]?.year_to){
      if(editProfileData.education[index]?.year_from>editProfileData.education[index]?.year_to)
      return true
    else
    return false
    }
    return false
  }


  export const viewMoreForExpertise=(viewMore:any)=>{
    return viewMore?"View less":"View more..."
  }

  export const  updateProfileState = (resData:any,editProfileData:any,editProfileError:any) => {
    console.log(resData,"resdata")
    const newEditProfileData = {
      ...editProfileData,
      name: resData?.profile_details?.name.trim() ? resData?.profile_details?.name : null,
      website: resData?.profile_details?.website_link,
      location: resData?.profile_details?.city,
      about: resData?.profile_details?.about,
      dribble: resData?.profile_details?.dribble,
      insta: resData?.profile_details?.instagram,
      linkedin:resData?.profile_details?.linkedin,
      skills:resData?.profile_details?.skills,
      companyName:resData.profile_details.company_details?.data.attributes.company_name,
      industry:resData.profile_details.company_details?.data.attributes.industry.id,
      designation:resData.profile_details.company_details?.data.attributes.designation.id,
      rateMin: resData?.profile_details?.min_per_hour ? resData?.profile_details?.min_per_hour : 600,
      rateMax: resData?.profile_details?.max_per_hour ? resData?.profile_details?.max_per_hour : 2000,
      language: resData?.profile_details?.languages
        ? resData?.profile_details?.languages.map((item:any) => ({ label: item, value: item }))
        : [],
      education: resData?.educations.length
        ? resData?.educations.map((item:any) => ({
            ...item,
            _destroy: 0,
          }))
        : editProfileData?.education,
    }
   
   

    return newEditProfileData
  }

  export const  updateProfileStateError = (resData:any,editProfileData:any,editProfileError:any) => {
   
    const newEditProfileError = {
      ...editProfileError,
      education: resData?.educations?.length
        ? resData?.educations.map(() => ({
            qualification: null,
            year_from: null,
            year_to: null,
            description: null,
          }))
        : editProfileError?.education,
    };

    return newEditProfileError
  }

  export const getExpertiseNonSelectedListData=(expertiseTempData:any)=>{
  
    const list=expertiseTempData?.map((item:any)=>({label:item?.name,value:item?.name}))
    return list
  }

  export const handleViewMoreCssBeforeAdd=(viewMore:any)=>{
    return !viewMore?"user_profile_skills_hide":""
  }
  
  export const handleEditProfileSKillsCssAfterAdd=(item:any)=>{
    return !item.view_more?"user_profile_skills_hide_added":""
  }

  export const createDataTemp = (ele:any, skillsData:any,editProfileData:any) => {
    return {
      value: ele,
      skills: skillsData?.data?.data?.map((item:any) => ({
        value: item?.name,
        select: editProfileData.skills.includes(item?.name),
        id: item.id,
      })),
    };
  };
