
import { Box, styled,Typography,Chip,Divider} from '@material-ui/core';
import {avatar} from "../../blocks/catalogue/src/assets"
import StarIcon from '@material-ui/icons/Star';
import { BlockComponent } from 'framework/src/BlockComponent'
import React from 'react'
const config = require("../../framework/src/config.js");

export interface Props {
handleOpen:(e: React.MouseEvent<HTMLElement, MouseEvent>,id:number)=>void
handleNavigation:(path:string)=>void
isOpen:boolean
data:Data
index:number
completedSkills:Skills[]
}

interface Data {
  "id": number;
  "city": string | null;
  "account_id": number;
  "photo": string | null;
  "background_color": string | null;
  "skills": Skills[],
  "min_per_hour": number | null,
  "max_per_hour": number | null,
  "profile_cover_image": string | null,
  "ratings": number,
  "views": number,
  "likes": number,
  "user_name": string | null,
  "average_rate_per_hour":number
}

interface Skills {
  "id": number,
  "name": string,
  "created_at": Date,
  "updated_at": Date,
  "category_id": number
}

interface S {
skills:Skills[];
showSkills:Skills[];
baseUrl:string;
}

interface SS {
}

export default class ProfileCard extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props)

    this.state = {
      skills:props.data.skills,
      showSkills:props.data.skills.slice(0, 2),
      baseUrl:config.baseUrl
    }

  }

  mouseEnterTimeout: ReturnType<typeof setTimeout> | null = null;

  handleMouseEnter = () => {
    this.mouseEnterTimeout = setTimeout(() => {
      this.setState({ showSkills: this.props.data.skills.slice(0, 5) });
    }, 500);
  };

  handlePopUpOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>{
    this.handleMouseLeave()
    this.props.handleOpen(e,this.props.index)
  }

  countAvg = (min:number | null,max:number | null) =>{
    let minValue : number = min === null ? 0 : min
    let maxValue : number = max === null ? 0 : max
    return (minValue + maxValue) / 2
  }

  formatNumber = (num:number) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(num % 1000000 === 0 ? 0 : 1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(num % 1000 === 0 ? 0 : 1) + 'k';
    } else {
        return num.toString();
    }
}

  handleMouseLeave = () => {
    if (this.mouseEnterTimeout) {
      clearTimeout(this.mouseEnterTimeout);
      this.mouseEnterTimeout = null;
      this.setState({showSkills:this.props.data.skills.slice(0, 2)})
    }
  };

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .profile_container": {
      width:394 , maxHeight:1000, minHeight:180, borderRadius:12, border:'1.15px solid #6D6D6D',
    },
    "& .avatar_username_container": {
      display:'flex', position:'relative',padding:"0 30"
    },
    "& .user_name_container": {
      paddingLeft:15, paddingTop:5
    },
    "& .username": {
      fontWeight:700, fontSize:20, color:'#FFF2E3',fontFamily: "Inter, sans-serif"
    },
    "& .city": {
      color:'#FFF2E3', fontSize:14,fontFamily: "Inter, sans-serif"
    },
    "& .stats_container": {
      display:'flex', justifyContent:'space-around', textAlign:'center',padding:'0 10'
    },
    "& .stats_heading": {
      color:'#7D7D7D', fontSize:14,fontFamily: "Inter, sans-serif"
    },
    "& .stats_result": {
      color:'#FFF2E3', fontSize:16, fontWeight:500,fontFamily: "Inter, sans-serif"
    }, "& .skills_container": {
      display: 'flex', flexWrap: 'wrap', padding: "20px", width:'90%', gap:5
    },
    "& .skills": {
      backgroundColor: '#1D1D1D', 
            color: '#FFF2E3', 
            fontSize: 14, 
            fontFamily: "Inter, sans-serif",
            fontWeight: 400,
            cursor: 'pointer',
            margin: '2px 0px',
            transition: 'opacity 3s ease-in-out, transform 3s ease-in-out'
    },
    "& .skills_count": {
      color: '#FFF2E3', 
              fontSize: 14, 
              fontWeight: 400,
              fontFamily: "Inter, sans-serif",
              cursor: 'pointer',
              margin: '2px 0px',
              transition: 'opacity 3s ease-in-out, transform 3s ease-in-out'
    }, "& .cover_image":{
      width:'100%',
      height:74.98,
      borderRadius:"11.54px 11.54px 0px 0px"

  }, "& .cover_image_blank":{
    backgroundColor:'black',
    borderRadius:"11.54px 11.54px 0px 0px"
},
[theme.breakpoints.down(1635)]: {
  "& .avatar_username_container": {
    padding:"0 30"
  },
  "& .user_name_container": {
    paddingLeft:15, paddingTop:5
  },
  "& .username": {
    fontWeight:700, fontSize:20
  },
  "& .city": {
    fontSize:14
  },
 "& .skills_container": {
   padding: "20px", width:'90%'
  },
  "& .cover_image":{
    width:'100%',
    height:74.98,
    borderRadius:"11.54px 11.54px 0px 0px"
},
  }
  }));

  render() {
    const {photo,user_name,city,ratings, background_color, views,likes,profile_cover_image, average_rate_per_hour} = this.props.data
    return (
      <this.MainWrapper>
        <Box key={this.props.index} data-test-id={`profile_detail_card_${this.props.index}`} className='profile_container backgroundColor'  >
           <Box>
            {
              profile_cover_image !== null ?  <img src={config.baseURL + profile_cover_image} className='cover_image' width={"100%"} height={74.98} alt="background" /> : <Box className='cover_image cover_image_blank' />
            }
            </Box> 
            <Box className='avatar_username_container'>
              {
                photo !== null ? <img src={config.baseURL + photo} height={96.9} width={96.9} style={{borderRadius:"50%", top:'-35', position:'relative'}} alt="avatar" /> : background_color !== null ? <Box style={{borderRadius:"50%", top:'-35', position:'relative' , backgroundColor:background_color, width:96.9 , height:96.9}}></Box> : <img src={avatar} height={96.9} width={96.9} style={{borderRadius:"50%", top:'-35', position:'relative'}} alt="avatar" />
              }
                <Box className='user_name_container'>
                    <Typography className='username'>{user_name}</Typography>
                    <Typography className='city'>{city}</Typography>
                </Box>
            </Box>
            <Box className='stats_container' >
                <Box >
                    <Typography className='stats_heading'>Views</Typography>
                    <Typography className='stats_result'>{this.formatNumber(views) }</Typography>
                </Box>
                <Divider orientation="vertical" style={{backgroundColor:"#545454", height:'inherit'}}/>
                <Box>
                    <Typography className='stats_heading'>Likes</Typography>
                    <Typography className='stats_result'>{ this.formatNumber(likes) }</Typography>
                </Box>
                <Divider orientation="vertical" style={{backgroundColor:"#545454", height:'inherit'}}/>
                <Box>
                    <Typography className='stats_heading'>Avg. rate per hour</Typography>
                    <Typography className='stats_result'>{`₹ ${average_rate_per_hour} pr/hr`}</Typography>
                </Box>
                <Divider orientation="vertical" style={{backgroundColor:"#545454", height:'inherit'}}/>
                <Box>
                    <Typography className='stats_heading'>Ratings</Typography>
                    <Box style={{display:'flex',justifyContent:'center',textAlign:'center',backgroundColor:ratings >= 4.0 ? 'green' : '#545454'}}>
                    <Typography style={{color: '#FFF2E3', fontSize:16, fontWeight:500}}>{ratings !== 0.0 ? ratings : "New"}</Typography>
                    {ratings !== 0.0 && <StarIcon style={{fontSize:16, color:'#FFF2E3', marginTop:2}}/>}
                    </Box>
                </Box>
            </Box>
            {this.props.isOpen && this.props.completedSkills.length > 0 && <Divider style={{backgroundColor:"#545454" ,width:'100%', marginTop:'5%'}}/>}
            <Box className='skills_container' onMouseLeave={this.handleMouseLeave}>

              {
                this.props.isOpen && this.props.completedSkills.length > 0 ?
                  <>
                  {this.props.completedSkills.map((skill, index) => (
                    <Chip key={index} className='skills' label={skill.name} />
                  ))}
                  </>
                :
                  <>
                  { this.state.showSkills.map((skill, index) => (
                    <Chip key={index} className='skills' label={skill.name} />
                  ))}
                  {(  this.state.skills.length > 2 && (this.state.skills.length - this.state.showSkills.length) !== 0) && (
                    <Chip className='skills'  onMouseEnter={this.handleMouseEnter}
                    onClick={(e)=>this.handlePopUpOpen(e)} label={`+ ${this.state.skills.length - this.state.showSkills.length}`} />
                )}
                  </>

              }
      </Box>
        </Box>
      </this.MainWrapper>
    )
  }
}
