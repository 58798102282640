// Customizable Area Start
import React from "react";
import { insta, linkdin, dribble, share,  profileImg, rating, expertise, rightArrow} from "../assets"; 
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import "../styles.css";

import PublicUserProfileController, { Props,ProfileDetails } from "./PublicUserProfileController.web";
import { Box, Typography } from "@material-ui/core";

interface Skill {
    id: number;
    select: boolean;
    name: string;
  }

const logoBox = (profile_details:ProfileDetails)=>{ 
    return (
    <div className="social-media-container">
        {profile_details.dribble ? <img src={dribble} alt="go to dribble" /> : ""}
        {profile_details.instagram ? <img src={insta} alt="go to instagram" /> : ""}
        {profile_details.linkedin ?  <img src={linkdin} alt="go to linkdin" /> : ""}
    </div>)
}

class PublicUserProfile extends PublicUserProfileController {
    constructor(props: Props) {
        super(props);
    }
    imgProfile =() =>{
        return (
            <div style={{position:"relative",marginTop:"-60px"}}>
                
                {this.state.profileImageData.profile_details.photo == null && (
                    <div   style={{...webStyle.profileImageBox,background:`${this.state.profileImageData.profile_details.background_color}`}}  > </div>
                )}
                
                {this.state.profileImageData.profile_details.photo && (
                 <img
                            src={this.imageProfile()}
                            style={webStyle.photoStyle}
                          
                            alt="profile icon"
                            data-test-id="profilebtn"
                            />          
                )}
            </div>
        )
    }

   forClientDetails = () =>{
        return(
            <div>
                <Box className="forClientDetails">
                    <Box>Company Name</Box>
                    <Box>{this.CompanyName()}</Box>
                </Box>
                <Box className="forClientDetails forClientDetails-designation">
                    <Box>Designation</Box>
                    <Box>{this.showDesignation()}</Box>
                </Box>
                <Box className="forClientDetails">
                    <Box>Industry</Box>
                    <Box>{this.showIndustry()}</Box>
                </Box>
            </div>
        )
    }

    userInfoContainer = () =>{
        return(
            <div className="info-container">
                {this.state.userRole === 'designer' ? 
                (
                    <>
                        <div className="part-data border-right">
                            <div className="info-data">
                                <div className="label-text">Profile Views</div>
                                
                                <div className="value-text">{this.profileView()}</div>
                            </div>
                        </div>
                        <div className="part-data border-right">
                            <div className="info-data">
                                <div className="label-text">Likes</div>
                                <div className="value-text">{this.showLike()}</div>
                            </div>
                        </div>
                        <div className="part-data border-right p-0">
                            <div className="info-data">
                                <div className="label-text">Avg paid rate</div>
                                <div className="value-text">{this.avgRate()}</div>
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <div className="part-data-client border-right">
                            <div className="info-data">
                                <div className="label-text">Jobs Posted</div>
                                
                                <div className="value-text">{this.profileView()}</div>
                            </div>
                        </div>
                )}
                
                <div className={`${this.state.userRole === 'designer' ? 'part-data ':'part-data-client'} left-text`}>
                    <div className="info-data">
                        <div className="label-text">Ratings</div>
                        <div className="value-text">
                            <div className="rating-text">4.5<img src={rating} alt="rating"/></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    handleLanguages = () => {
      return (
        <div className="group-container d-flex flex-column w-100 h-auto p-0">
                    <div className="d-flex flex-column g-16 p-16">
                      <div className="text-elm heading-data">Languages</div>
                      <div className="Languages-flex d-flex w-100 g-8 row-gap-12">
                        {this.state.profileImageData.profile_details
                          .languages &&
                        this.state.profileImageData.profile_details.languages
                          .length > 0 ? (
                          this.state.profileImageData.profile_details.languages.map(
                            (language:any, index:any) => (
                              <div key={index} className="skill-chip">
                                {language}
                              </div>
                            )
                          )
                        ) : (
                          <div style={webStyle.defaultText}>
                            No Languages added
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
      )
    } 
    handleUserFollowers =()=>{
      return (
        <>
        
        {this.state.userRole === "designer" && (
          <>
            <div className="group-container p-0">
              <div
                className="rate-container"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="text-elm heading-data">Rate</div>
                <div className="rate-chip">
                  {this.state.profileImageData.profile_details
                    ?.min_per_hour &&
                  this.state.profileImageData.profile_details
                    ?.max_per_hour ? (
                    `${this.state.profileImageData.profile_details.min_per_hour} - ${this.state.profileImageData.profile_details.max_per_hour} ₹/hr`
                  ) : (
                    <div style={webStyle.defaultText}>Add info</div>
                  )}
                </div>
              </div>
            </div>
            <div className="group-container d-flex flex-column w-100 h-auto p-0">
              <div className="d-flex flex-column g-16 p-16 pb-0">
                <div className="text-elm heading-data expertise-heading d-flex">
                  <img src={expertise} alt="Expertise" />
                  Expertise
                </div>
                <div className="d-flex w-100 g-10 py-11">
                  {this.state.profileImageData.profile_details
                    .expertise_category &&
                  this.state.profileImageData.profile_details
                    .expertise_category.length > 0 ? (
                    this.state.profileImageData.profile_details.expertise_category.map(
                      (expertise:any, index:any) => (
                        <div key={index} className="expertise-data">
                          {expertise}
                        </div>
                      )
                    )
                  ) : (
                    <div style={webStyle.defaultText}>Add info</div>
                  )}
                </div>
                <hr className="p-0 line-elm" />
              </div>
              <div className="d-flex flex-column g-16 p-16 pt-0">
                <div className="text-elm heading-data">Skills</div>
                <div
                  className=""
                  style={{
                    ...webStyle.skillContainer,
                    flexWrap: "wrap",
                  }}
                >
                  {this.state.profileImageData.profile_details
                    .skills &&
                  this.state.profileImageData.profile_details.skills
                    .length > 0 ? (
                    this.state.profileImageData.profile_details.skills.map(
                      (skill: Skill, index:any) => (
                        <div
                          key={index}
                          className="skill-chip"
                          style={webStyle.skillChip}
                        >
                          {skill.name}
                        </div>
                      )
                    )
                  ) : (
                    <div style={webStyle.defaultText}>Add info</div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) }
        </>
      )
    }
    render() {         
      return (
        <div className="user-profile-container">
          <div className="user-profile-header">
            <NavigationMenu {...this.props} activeTab="UserProfileBasicBlock" />
          </div>
          <div className="body-container">
            <div className="profile-section flex-column">
              <div className="profile-bg-container">
                <div
                  style={{
                    maxWidth: "100%",
                    width: "643px",
                    height: "220px",
                    objectFit: "cover",
                  }}
                >
                  <img
                    width="643px"
                    height="220px"
                    style={webStyle.imageBackground}
                    src={this.imageUrl()}
                    alt="banner image"
                    data-testId="backgroundimg"
                  />
                </div>
                <div className="icon-elm-container">
                  <div className="header-icon-container">
                    {logoBox(this.state.profileImageData.profile_details)}
                    <img src={share} alt="share profile" />
                  </div>
                  
                </div>
              </div>
              <div className="user-basic-info flex-column">
                {this.imgProfile()}

                <div className="profile-data flex-column">
                  <div className="max-data text-elm name">
                   
                    {this.state.profileImageData.profile_details.name}
                  </div>
                  <div className="max-data mt-12 text-elm site-data">
                    {this.websideShow()}
                  </div>
                  <div className="max-data mt-8 text-elm member-data">
                    {this.memberSince()}
                  </div>
                  <div className="max-data mt-16 text-elm location-data">
                    {" "}
                    {this.showLoaction()}
                  </div>
                  <div className="Btngroup">
                  <button className="FollowBtn" >Follow</button>
                  <button className="MessageBtn" >Message</button>
                  </div>
                </div>
              </div>
              <div className="details-section flex-column page-color">
                <div
                  className="text-elm"
                  style={{ display: "flex", width: "100%" }}
                >
                  <div
                    className={
                      this.state.userRole === "designer"
                        ? "profile-details"
                        : "profile-details-client"
                    }
                  >
                    {this.state.userRole === "designer" && "Profile Details"}
                    
                  </div>
                </div>
                <div
                  className=" flex-column"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="group-container flex-column">
                    <Box>
                      <div className="text-elm heading-data">
                        {this.aboutRoleShow()}
                      </div>
                      <div className="text-elm about-data mt-16">
                        {this.showAboutInfo()}
                      </div>
                      {this.state.userRole === "client" &&
                        this.forClientDetails()}
                    </Box>
                  </div>
                  {this.userInfoContainer()}

                    {this.handleUserFollowers()}
                  <div className="group-container d-flex w-100 h-auto p-0 mt-16">
                    <div className="flex-elm flex-column g-16">
                      <div className="text-elm heading-data">Followers</div>
                      <div className="text-elm heading-data text-secondary">
                        {this.followers()}
                      </div>
                    </div>
                    <div className="flex-elm flex-column g-16">
                      <div className="text-elm heading-data">Following</div>
                      <div className="text-elm heading-data text-secondary">
                        {this.showFollowing()}
                      </div>
                    </div>
                  </div>
                  {this.state.userRole === 'client' && (
                     <div className="ActiveOpportunity">
                     <Box className="ActiveOpportunity-content">
                     <Typography className="ActiveOpportunity-content">Active Opprtunity</Typography>
                     </Box>
                     <Box >
                         <Typography className="ActiveOpportunity-content">12</Typography>
                     </Box>
                 </div>
                  )}
                  <div className="group-container w-100 h-auto p-0">
                    <div className="d-flex flex-column g-40 p-16 experience-head">
                      <div className="d-flex w-100 g-16 heading-space-data text-elm heading-data">
                        <div className="text-elm heading-data g-24">
                          Ratings
                          <div className="value-text">
                            <div className="rating-text">
                              4.5
                              <img src={rating} alt="rating" />
                            </div>
                          </div>
                        </div>
                        236 Reviews
                      </div>
                      <div className="d-flex w-100 g-24 flex-column">
                        <div className="d-flex g-16 flex-column">
                          <div className="rating-experience-text">
                            Efficient to work with Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labpr...
                          </div>
                          <div className="experience-left-text justify-left">
                            <img src={profileImg} alt="user experience" />
                            Anil Mishra (Senior UI/UX Designer)
                          </div>
                        </div>
                        <hr className="p-0 line-elm" />
                        <div className="d-flex g-16 flex-column">
                          <div className="rating-experience-text">
                            Efficient to work with Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit, sed do eiusmod tempor
                            incididunt ut labpr...
                          </div>
                          <div className="experience-left-text justify-left">
                            <img src={profileImg} alt="user experience" />
                            Anil Mishra (Senior UI/UX Designer)
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.userRole === "designer" && (
                    <>
                      <div className="group-container w-100 h-auto p-0">
                        <div className="d-flex flex-column g-40 p-16">
                          <div className="d-flex experience-head g-16">
                            <div className="text-elm heading-data">
                              Work Experience {this.totalWorkExp}
                            </div>
                            {this.state.profileImageData.profile_details
                              .work_experience.length > 0 && (
                              <div className="view-all">
                                <div className="text-data">view all</div>

                                <img
                                  src={rightArrow}
                                  alt="view more work experience"
                                />
                              </div>
                            )}
                          </div>
                          <div className="d-flex w-100 g-24 flex-column">
                            <div className="d-flex g-16 flex-column">
                              {this.state.profileImageData.profile_details
                                .work_experience.length > 0 ? (
                                this.state.profileImageData.profile_details.work_experience.map(
                                  (workexp:any, index:any) => {
                                    return (
                                      <Box key={index}>
                                        <div className="flex-header">
                                          <div className="experience-left-text">
                                            <img
                                              src={profileImg}
                                              alt="user experience"
                                            />
                                            {workexp.company_name}
                                          </div>
                                          <div className="experience-right-text">
                                            <span>
                                              {workexp.duration} - Present
                                            </span>
                                            <span style={{ padding: "0 10px" }}>
                                              &bull;
                                            </span>
                                            <span>
                                              {workexp.total_years_worked} years
                                            </span>
                                          </div>
                                        </div>
                                        <div className="experience-text">
                                          {workexp.role}
                                        </div>
                                        <hr className="p-0 line-elm" />
                                      </Box>
                                    );
                                  }
                                )
                              ) : (
                                <div style={webStyle.defaultText}>
                                  {" "}
                                  No Work Experience added
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-container d-flex flex-column w-100 h-auto p-0">
                        <div className="d-flex flex-column g-16 p-16">
                          <div className="text-elm heading-data">Education</div>
                          {this.state.profileImageData.educations &&
                          this.state.profileImageData.educations.length > 0 ? (
                            this.state.profileImageData.educations.map(
                              (education:any, index:any) => (
                                <React.Fragment key={index}>
                                  <div className="education-data">
                                    <div
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        fontFamily: "Inter",
                                        lineHeight: "19.36px",
                                      }}
                                    >
                                      {education.description}
                                    </div>

                                    <div
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: 600,
                                        fontFamily: "Inter",
                                        lineHeight: "16.94px",
                                        color: "#666666",
                                      }}
                                    >
                                      {education.qualification}{" "}
                                      {this.formatYears(
                                        education.year_from,
                                        education.year_to
                                      )}{" "}
                                    </div>
                                  </div>
                                </React.Fragment>
                              )
                            )
                          ) : (
                            <div style={webStyle.defaultText}>
                              No Education added
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {this.handleLanguages()}
                  <div className="group-container d-flex flex-column w-100 h-auto p-0">
                    <div className="d-flex flex-column g-8">
                        {this.state.userRole === "designer" && (
                            <>
                            <div className="education-data history-data p-16">
                        <div className="text-elm heading-data text-secondary normal-weight">
                          View Highlights History
                        </div>
                        <button className="view-details">
                          <img src={rightArrow} alt="view history" />
                        </button>
                      </div>
                      <hr className="p-0 line-elm" />
                            </>
                        )}
                      
                      <div className="education-data history-data p-16">
                        <div className="text-elm heading-data text-secondary normal-weight">
                          View Activity
                        </div>
                        <button className="view-details">
                          <img src={rightArrow} alt="view history" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

export default PublicUserProfile;

const webStyle ={
    paperProfile:{
        position: 'absolute',
        width:488,
        height:497,
        backgroundColor: '#222222',
        border: '2px solid #000',
        boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
        borderRadius: '7px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
       
      },
    paper: {
        position: 'absolute',
        width:1141,
        height:513,
        backgroundColor: '#222222',
        border: '2px solid #000',
        boxShadow: '5px 5px 20px rgba(0,0,0,0.3)',
        borderRadius: '7px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
       
      },
      modalTitle: {
        fontSize: "20px",
        color: "#FFF2E3",
        fontWeight: 600,
        lineHeight:"24.2px",
        fontFamily: "Inter",   
        width:"100%"     
      },
      profileImageBox :{
        width:"100px",
        height:"100px",
        borderRadius:"50px",
      } as any,
      subTitle:{
        marginTop:"16px",
        fontSize: "16px",
        color: "#FFF2E3",
        fontWeight: 400,
        fontFamily: "Inter",
        lineHeight:"24.88px",
      },
      imgBox: {
          width: '100%',
          marginTop: '32px',
        height: 'auto',       
      },
      addImage: {        
        top: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100px', 
        height: '100px', 
      },
      canBtn:{
        fontFamily: "Inter",      
        fontSize: "16px",
        linehHeigth: "24px",
        fontWeight: 500,
        color: '#FFF2E3',
        backgroundColor:'#0D0D0D',       
        borderRadius:"7px",  
        padding:"10px 30px 10px 30px",  
        width:"max-content",     
        },
      saveBtn:{
        fontFamily: "Inter",      
        fontSize: "16px",
        fontWeight: 500,
        backgroundColor:'#FFF2E3',       
        linehHeigth: "24px",
        color: '#121212',
        borderRadius:"7px",  
        width:"max-content",       
        padding:"10px 30px 10px 30px",      
           },
      btnBox:{marginTop:"40px" , display:"flex", gap:"24px" , justifyContent:"center"},
      imgBox2:{
        marginTop: '47px',
        marginLeft:"auto",
        marginRight:"auto",
        width: '226px',
        height: '226px',
        borderRadius:"50%"
      },
      closeIcon:{
        marginLeft: 'auto',
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        cursar:"pointer"
      },
      photoStyle:{
        width:"156px",height:"156px" ,borderRadius:"50%"
      },
      imageBackground:{
        borderRadius:"24px 24px 0px 0px",
        width:"643px",
        heigth:"220px"
      },
      editIcon:{
        cursor:"pointer"
      },
      defaultText:{
        fontFamily: "Inter",     
        fontSize: "16px",
        linehHeigth: "24px",
        fontWeight: 500,
        color: '#535353',        
      },
      skillContainer:{
        display: 'flex',
        gap: '8px',
        width: '100%',        
    },
    skillChip:{
        minWidth: 'max-content',        
    }
}
// Customizable Area End



    
