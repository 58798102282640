import React from "react";
// Customizable Area Start
import { bg, logo } from "./assets";
import {
  Box,
  Card,
  FormHelperText,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  withStyles,
  Checkbox,
  Button,
  styled,
} from "@material-ui/core";
import { configJSON } from "./EmailAccountRegistrationController";
import ToastMSG from "../../../components/src/ToastMSG.web";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      height: "48.68px",
      padding: "12.35px 10.09px",
      "& fieldset": {
        borderColor: "#252525",
        borderWidth: "1.62px",
        borderRadius: "8.11px",
      },
      "&:hover fieldset": {
        borderColor: "#252525",
        borderWidth: "1.62px",
        borderRadius: "8.11px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#252525",
        borderWidth: "1.62px",
        borderRadius: "8.11px",
      },
    },
    "& p.MuiFormHelperText-root": {
      fontSize: "14px",
      lineHeight: "16.94px",
      color: "#FF7878",
      fontFamily: "Inter, sans-serif",
    },
  },
})(TextField);
const CustomRadio = withStyles({
  root: {
    color: "#fff2e3",
    "&.MuiTypography-body1": {
      color: "#fff2e3",
    },
  },
  checked: {
    "&.Mui-checked": {
      color: "#fff2e3",
    },
  },
})(Radio);

const CustomButton = withStyles({
  root: {
    textTransform: "none",
  },
})(Button);

const CustomCheckBox = withStyles({
  root: {
    color: "#d9d9d9",
    width: "20px",
    height: "20px",
    borderRadius: "2.3px",
    border: "none",
  },
  checked: {
    "&.Mui-checked": {
      color: "#111111",
    },
  },
})(Checkbox);

import PostEmailAccountRegistrationController, {
    Props
} from "./PostEmailAccountRegistrationController.web";

// Customizable Area End

export default class PostEmailAccountRegistration extends PostEmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start 
        return (
      <>
        <UserMainContainer>
          <Box sx={custombox.mainContainer}>
          <ToastMSG data-testId="toastMessageId" close={() =>this.handleCloseToast()} message={this.state.message} open={this.state.open} action={this.state.action} />
            <div>
              <Container className="container">
                <Card style={custombox.root}>
                  <Box data-test-id="formikFormData" className="accountForm">
                    <CardContent className="cardStyle">
                      <Grid container className="logoContainer">
                        <Grid item xs={12}>
                          <img src={logo} />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className="headerText">
                            {configJSON.welcomeHeading}
                          </Typography>
                          <Typography className="paraText">
                            {configJSON.paraText}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className="customGridData">
                          <Box className="userData">
                            <Box className="userImage">
                              <img className="userImage" src={this.state.accountData.picture}/>
                            </Box>
                            <Box className="userDetails">
                              <Typography className="userName">
                                {this.state.accountData.name}
                              </Typography>
                              <Typography className="userEmail">
                                {this.state.accountData.email}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} className="customUserName">
                          <InputLabel
                            style={{
                              ...custombox.inputLabel,
                              marginTop: "20px",
                            }}
                          >
                            {configJSON.usernameText}
                          </InputLabel>
                          <CustomTextField
                            fullWidth
                            variant="outlined"
                            name="username"
                            value={this.state.accountData.given_name}
                            onChange={this.handleUserName}
                            data-testId="textUsername"
                            required
                            inputProps={{ className: "textFieldProp" }}
                          />
                          <FormHelperText className="errorMessage">
                            {this.state.userErrorMessage}
                          </FormHelperText>
                        </Grid>

                        <Grid container className="radioButtonContainer">
                          <Grid item xs={12} className="mainRadioButton">
                            <InputLabel
                              style={{
                                ...custombox.inputLabel,
                                marginBottom: "15.2px",
                              }}
                            >
                              {configJSON.profileSelect}
                            </InputLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              row
                              data-testId="selectProfileTestId"
                              value={this.state.selectUserType}
                              onChange={(event) =>
                                this.handleProfileSelect(event.target.value)
                              }
                            >
                              <FormControlLabel
                                value="1"
                                control={
                                  <CustomRadio className="radioButton" />
                                }
                                label={
                                  <span className="clientLabel">
                                    {configJSON.clientText}
                                  </span>
                                }
                              />
                              <FormControlLabel
                                value="2"
                                control={
                                  <CustomRadio className="customRadioBtn" />
                                }
                                label={
                                  <span className="designerLabel">
                                    {configJSON.designerText}
                                  </span>
                                }
                              />
                            </RadioGroup>
                          </Grid>
                          <Box className="tncContainer" />
                          <Grid item xs={12} className="tncMainBox">
                            <Box
                              data-testId="TncBoxTestId"
                              onClick={this.handleAcceptConditions}
                            >
                              <CustomCheckBox
                                data-testId="checkBoxTestId"
                                checked={this.state.checkCondition}
                                style={{
                                  backgroundColor:
                                    !this.state.checkCondition &&
                                    this.state.isSubmit
                                      ? "#FF7878"
                                      : "#d9d9d9",
                                  color:
                                    !this.state.checkCondition &&
                                    this.state.isSubmit
                                      ? "#FF7878"
                                      : "",
                                  padding: "0",
                                }}
                              />
                            </Box>
                            <Box
                              sx={
                                !this.state.checkCondition &&
                                this.state.isSubmit
                                  ? {
                                      ...custombox.typoGraphy,
                                      color: "#FF7878",
                                    }
                                  : custombox.typoGraphy
                              }
                            >
                              {configJSON.acceptText}{" "}
                              <span className="tncText">
                                {configJSON.tncText}
                              </span>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="accountMainBox">
                          <CustomButton
                            data-testId="createAccountBtnTestId"
                            onClick={this.postSocialSignUp}
                            className="accountBtn"
                            variant="contained"
                          >
                            {configJSON.accountBtn}
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Box>
                </Card>
              </Container>
            </div>
          </Box>
        </UserMainContainer>
      </>
    );
        // Customizable Area End
    }
}
// Customizable Area Start
const UserMainContainer = styled(Box)({
  "& .headerText": {
    fontSize: "27.55px",
    lineHeight: "33.35px",
    marginTop: "21.37px",
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#FFF2E3",
    fontWeight: 600,
  },
  "& .paraText": {
    fontSize: "12px",
    lineHeight: "16.94px",
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#6C6C6C",
    textAlign: "center",
    fontWeight: 400,
  },
  "& .textFieldProp": {
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#fff2e3",
    fontSize: "14px",
    lineHeight: "16.94px",
  },
  "& .radioButtonContainer": {
    display: "block",
  },
  "& .errorMessage": {
    color: "#FF7878",
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
    lineHeight: "16.94px",
  },
  "& .errorAcceptLabel": {
    color: "rgb(255, 120, 120)",
    fontSize: "14px",
    textAlign: "left",
    fontFamily: "Inter, sans-serif",
    lineHeight: "16.94px",
  },
  "& .userName": {
    fontSize: "16px",
    lineHeight: "19.36px",
    height: "19px",
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#FFF2E3",
    fontWeight: 600,
  },
  "& .container": {
    maxWidth: "630px",
  },
  "& .userEmail": {
    fontSize: "14px",
    lineHeight: "16.94px",
    marginTop: "5px",
    width: "181px",
    height: "19px",
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#FFF2E3",
    fontWeight: 400,
  },
  "& .acceptLabel": {
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#FFF2E3",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "16.94px",
  },
  "& .userDetails": {
    alignItems: "center",
    display: "flex",
    marginTop: "5px",
    justifyContent: "center",
    flexDirection: "column",
  },
  "& .userImage": {
    width: "104px",
    height: "104px",
    borderRadius: "50%",
    background: "#D9D9D9",
  },
  "& .userData": {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "20px",
  },
  "& .customGridData": {
    display: "flex",
    flexDirection: "column",
    gap: "10.59px",
    width: "433.26px",
    marginTop: "5.02px",
  },
  "& .customUserName": {
    display: "flex",
    flexDirection: "column",
    gap: "10.59px",
    width: "433.26px",
    marginTop: "19.48px",
  },
  "& .mainRadioButton": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "26.69px",
  },
  "& .radioButton": {
    marginLeft: "20px",
    backgroundColor: "#FFFFFF14",
  },
  "& .customRadioBtn": {
    marginLeft: "50px",
    backgroundColor: "#FFFFFF14",
  },
  "& .clientLabel": {
    fontFamily: "Inter, sans-serif",
    color: "#fff2e3",
    fontSize: "14px",
    lineHeight: "16.94px",
    marginLeft: "11px",
  },
  "& .designerLabel": {
    fontFamily: "Inter, sans-serif",
    color: "#fff2e3",
    fontSize: "14px",
    lineHeight: "16.94px",
    marginLeft: "11px",
  },
  "& .tncMainBox": {
    marginTop: "31.04px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "17px",
  },
  "& .accountMainBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "27px",
  },
  "& .accountBtn": {
    backgroundColor: "#FFF2E3",
    fontFamily: "Inter-SemiBold, sans-serif",
    color: "#121212",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.38px",
    height: "44px",
    padding: "10px 30px",
  },
  "& .tncText": {
    textDecoration: "underline",
  },
  "& .logoContainer": {
    alignItems: "center",
    flexDirection: "column",
  },
  "& .accountForm": {
    margin: "0px",
    marginBottom: "0px",
  },
  "& .cardStyle": {
    margin: "0 96.87px",
    paddingLeft: "0px",
    paddingRight: "3px",
  },
  "& .tncContainer": {
    margin: "0",
    border: "1px solid #FFF2E3",
    height: "0",
    marginTop: "29px",
  },
});
const custombox = {
  labelFileInput: {
    cursor: "pointer",
    fontFamily: "Inter, sans-serif",
  },
  uploadBtn: {
    color: "#fff2e3",
    fontSize: "16px",
    letterSpacing: "0.38px",
    lineHeight: "24px",
    fontFamily: "Inter-SemiBold, sans-serif",
  },
  mainContainer: {
    backgroundImage: `url(${bg})`,
    backgroundSize: "100vw",
    backgroundRepeatX: "no-repeat",
    padding: "0px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  errorText: {
    fontSize: "14px",
    lineHeight: "16.94px",
    color: "#FF7878",
    fontFamily: "Inter, sans-serif",
    marginBottom: "0",
    marginTop: "5px",
  },
  root: {
    background: "rgb(17, 17, 17, 0.9)",
    height: "auto",
    borderRadius: "24.11px",
    overflow: "unset",
    paddingTop: "32px",
    marginTop: "30px",
    marginBottom: "30px",
    width: "630px",
  },
  inputLabel: {
    color: "#6C6C6C",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    lineHeight: "16.94px",
  },
  typoGraphy: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    lineHeight: "16.94px",
    textAlign: "left",
    color: "#fff",
    fontWeight: 300,
  },
  typoGraphysignup: {
    fontSize: "14px",
    lineHeight: "16.94px",
    letterSpacing: "0em",
    color: "#fff2e3",
    fontFamily: "Inter, sans-serif",
  },
  dottedBorder: {
    borderRadius: "50%",
    border: "1px dotted #000",
  },
};
// Customizable Area End