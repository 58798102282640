import React from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  Typography,
  Box,
  Divider,
  Modal,
} from "@material-ui/core";
import WorkOppertunityModalController, {
  Props,
} from "./WorkOppertunityModalController";
import {
  location,
  likeIcon2,
  eyeIcon,
  featureIcon,
  closeIcon,
} from "../assets";
const config = require("../../../../framework/src/config.js")

// Customizable Area End

export default class WorkOppertunityModal extends WorkOppertunityModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { openOppertunity, handleClose } = this.props;
    const { workOppertunityData, isChanged } = this.state;
    const htmlDAta = workOppertunityData?.attributes.deliverables;
    const workData = this.createObjectArray();

    return (
      <div>
        <Modal
          open={openOppertunity}
          // onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box sx={webStyle.modal}>
            <Box sx={webStyle.closeIconBox}>
            <Box style={{height:40}}>
            <img
                style={webStyle.closeIconcss}
                src={closeIcon}
                data-test-id = "closeButton"
                alt="Close"
                onClick={()=>handleClose(isChanged)}
              />
            </Box>
              <Box sx={webStyle.paper}>
                <Box>
                  <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box style={webStyle.titleBox}>
                        <Typography style={webStyle.modalTitle}>
                          {workOppertunityData.attributes.title}
                        </Typography>
                        <Box style={webStyle.buttonBox}>
                          <Button
                            disabled={this.state.isLoading}
                            data-test-id = "saveWork"
                            onClick={this.handleSaveOrUnsaveWork}
                            style={{
                              ...webStyle.saveBtn,
                              textTransform: "none",
                            }}
                          >
                            {this.state.workOppertunityData.attributes.is_saved_opportunity ? "Unsave work" : "Save work" } 
                          </Button>
                          <Button
                            style={{
                              ...webStyle.sendBtn,
                              textTransform: "none",
                            }}
                          >
                            Send proposal
                          </Button>
                        </Box>
                      </Box>
                      <Divider style={webStyle.dividerStyle} />
                    </Grid>
                    <Grid
                      item
                      xl={8}
                      lg={8}
                      md={8}
                      sm={6}
                      xs={12}
                      style={{ borderRight: "1px solid rgba(84, 84, 84, 1)" }}
                    >
                      <Box style={webStyle.paddingBox}>
                        <Typography
                          style={webStyle.descriptionTitle}
                          data-testId="workdescription"
                        >
                          {" "}
                          Work description
                        </Typography>
                        <Box style={webStyle.container}>
                          <Typography
                            style={{
                              ...webStyle.description,
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {workOppertunityData.attributes.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider style={webStyle.dividerStyle} />
                      <Box
                        style={{
                          ...webStyle.btnBox,
                          boxSizing: "border-box",
                          flexDirection: "column",
                        }}
                      >
                        <Typography style={{ ...webStyle.skillTag }}>
                          Skill tags
                        </Typography>
                        <Box
                          style={{ ...webStyle.btnContainer, flexWrap: "wrap" }}
                        >
                          {workOppertunityData.attributes.skills.map(
                            (skill) => {
                              return (
                                <Button
                                  key={skill.id}
                                  style={webStyle.btnStyle}
                                >
                                  {skill.name}
                                </Button>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                      <Divider style={webStyle.dividerStyle} />
                      <Box style={webStyle.paddingBox}>
                        <Typography style={webStyle.skillTag}>
                          Deliverables
                        </Typography>
                        <Box
                          style={{
                            ...{
                              ...webStyle.DeliverablesList,
                              ...webStyle.container,
                            },
                            maxWidth: "900px",
                            height: "auto",
                            overflow: "auto",
                          }}
                        >
                          <Box
                            style={{
                              ...{
                                ...webStyle.listItem,
                                wordWrap: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "pre-line",
                              },
                              width: "100%",
                              minHeight: "auto",
                              maxHeight: "100%",
                              overflowY: "auto",
                            }}
                            dangerouslySetInnerHTML={{ __html: htmlDAta }}
                          />
                        </Box>
                      </Box>
                      {workData.length > 0 && (
                        <>
                          <Divider style={webStyle.dividerStyle} />
                          <Box style={webStyle.paddingBox}>
                            <Box>
                              <Typography style={webStyle.descriptionTitle}>
                                Project references
                              </Typography>
                              <Box
                                style={{
                                  ...webStyle.DeliverablesList,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                {workData.map((item, index) => (
                                  <Box key={index} data-testId="workProject">
                                    <Box
                                      style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        gap: "3px",
                                      }}
                                    >
                                      <Typography style={webStyle.listNumber}>
                                        {item.number}.
                                      </Typography>
                                      <Box>
                                        <Box style={{ marginLeft: "10px" }}>
                                          {item.type === "fileName" && (
                                            <>
                                              {item.fileName &&
                                                item.fileName.map(
                                                  (fileName, fileIndex) => (
                                                    <Typography data-test-id = "pdfDownload"
                                                      onClick={()=> this.downloadProjectPdf(fileName, config.baseURL + item.fileUrl)}
                                                      key={fileIndex}
                                                      style={{
                                                        ...webStyle.listItem1,
                                                        wordBreak: "break-word",
                                                        whiteSpace: "pre-wrap",
                                                      }}
                                                    >
                                                      {fileName}
                                                    </Typography>
                                                  )
                                                )}
                                            </>
                                          )}
                                        </Box>
                                          
                                        {item?.link && (
                                          // item.link.map((link:string) => (
                                            <Typography
                                              key={index}
                                              component={"a"}
                                              href={item.link.trim()}
                                              target="_blank"
                                              style={{ ...webStyle.listItem2, display: "block" }} // Ensures each link starts on a new line
                                            >
                                              {item.link.trim()}
                                            </Typography>
                                          // ))
                                        )}
                                        

                                        {item.type === "project" && (
                                          <Box style={webStyle.DeliverItem3}  >
                                            {console.log("modal")}
                                            <Box >
                                              <img
                                                style={webStyle.listImg}
                                                src={item.image}
                                                alt={item.title}
                                                height="281.67px"
                                                width="415.09px"
                                              />
                                              <Box
                                                style={webStyle.refContainer}
                                              >
                                                <Box>
                                                  <Typography
                                                    style={webStyle.imgTitle}
                                                  >
                                                    {item.title}
                                                  </Typography>
                                                  <Box
                                                    style={{
                                                      display: "flex",
                                                      gap: "10px",
                                                      alignItems: "center",
                                                      marginTop: "4px",
                                                    }}
                                                  >
                                                    <Button
                                                      style={{
                                                        ...webStyle.refBtn,
                                                        textTransform: "none",
                                                        fontSize: "11px",
                                                      }}
                                                    >
                                                      club
                                                    </Button>
                                                    <Typography
                                                      style={webStyle.imgUser}
                                                    >
                                                      {item.username}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                                <Box
                                                  style={webStyle.imgeContainer}
                                                >
                                                  <Box
                                                    style={webStyle.imageIcon}
                                                  >
                                                    <Typography
                                                      style={
                                                        webStyle.iconNumber
                                                      }
                                                    >
                                                      {item.views}
                                                    </Typography>
                                                    <img
                                                      src={eyeIcon}
                                                      alt="view"
                                                      height="17.6px"
                                                      width="24.2px"
                                                    />
                                                  </Box>
                                                  <Box
                                                    style={webStyle.imageIcon}
                                                  >
                                                    <Typography
                                                      style={
                                                        webStyle.iconNumber
                                                      }
                                                    >
                                                      {item.likes}
                                                    </Typography>
                                                    <img
                                                      src={likeIcon2}
                                                      alt="like"
                                                      height="16.5px"
                                                      width="18.7px"
                                                    />
                                                  </Box>
                                                  <Box
                                                    style={webStyle.imageIcon}
                                                  >
                                                    <Typography
                                                      style={
                                                        webStyle.iconNumber
                                                      }
                                                    >
                                                      {item.savedCount}
                                                    </Typography>
                                                    <img
                                                      src={featureIcon}
                                                      alt="save"
                                                      height="22px"
                                                      width="24px"
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Grid>
                    <Grid item xl={4} lg={4} sm={6} xs={12}>
                      <Box
                        style={{ ...webStyle.workBox, flexDirection: "column" }}
                      >
                        <Box>
                          <Typography style={webStyle.titleStyle}>
                            Work type
                          </Typography>
                          <Box style={{ marginTop: "14px" }}>
                            <Button
                              style={{
                                ...webStyle.workBtn,
                                textTransform: "none",
                                minWidth: "142px",
                              }}
                            >
                              {workOppertunityData.attributes.work_type}
                            </Button>
                          </Box>
                        </Box>
                        <Box>
                          <Box style={webStyle.location}>
                            <img src={location} />
                            <Typography style={webStyle.titleStyle}>
                              Location
                            </Typography>
                          </Box>
                          { 
                          <Box
                          style={{ ...webStyle.locationContainer, flexWrap: "wrap" }}
                        >
                          {this.state.locations.map(
                            (value,index) => {
                              return (
                                <Button
                                  key={index}
                                  style={{ ...webStyle.btnStyle, ...webStyle.locationBtnStyle }}
                                >
                                  {value}
                                </Button>
                              );
                            }
                          )}
                        </Box>
                          }
                        </Box>
                        <Box>
                          <Typography style={webStyle.titleStyle}>
                            Experience level
                          </Typography>
                          <Typography style={webStyle.description}>
                            {workOppertunityData.attributes.experience_level}
                          </Typography>
                        </Box>
                        <Typography style={webStyle.titleStyle}>
                          {
                            workOppertunityData.attributes
                              .required_hours_per_week
                          }{" "}
                          Hours pr/week
                        </Typography>
                        <Box style={webStyle.btnHourBox}>
                          <Typography style={webStyle.priceHour}>
                            Rs. {workOppertunityData.attributes.rate_amount}{" "}
                            {this.getRateTypeLabel()}{" "}
                          </Typography>
                          <Typography style={webStyle.month}>
                            {workOppertunityData.attributes.project_timeline}{" "}
                            {
                              workOppertunityData.attributes
                                .project_timeline_type
                            }{" "}
                          </Typography>
                        </Box>
                        <Box>
                          {workOppertunityData.attributes.start_date ? (
                            <>
                              <Typography style={webStyle.titleStyle}>
                                Start date
                              </Typography>
                              <Typography style={webStyle.description}>
                                {workOppertunityData.attributes.start_date &&
                                  this.formatDate(
                                    workOppertunityData.attributes.start_date
                                  )}
                              </Typography>
                            </>
                          ) : null}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  modal: {
    overflowY: "auto",
    maxHeight: "90vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "1490px",
    maxHeight: "90vh",
    backgroundColor: "#222222",
    borderRadius: "8px",
    overflowY: "auto",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  modalTitle: {
    fontSize: "20px",
    color: "#FFF2E3",
    fontWeight: 700,
    fontFamily: "Inter",
  },
  descriptionBox: {
    padding: "61px",
  },
  description: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    linehHeigth: "30px",
    color: "#FFF2E3",
    marginTop: "11px",
  },
  descriptionTitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  titleBox: {
    padding: "20px 26px 20px 58px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btnStyle: {
    fontFamily: "Inter",
    marginTop: "24px",
    backgroundColor: "rgb(50, 50, 50)",
    fontSize: "12px",
    fontWeight: 600,
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    borderRadius: "21px",
    border: " 1px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    width: "max-content",
    height: "37px",
  },
  locationBtnStyle:{
    marginTop: 0,
  },
  skillTag: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  btnContainer: {
    display: "flex",
    gap: "16px",
    width: "100%",
  },
  locationContainer: {
    display: "flex",
    gap: "11px",
    width: "inherit",
    paddingRight:51
  },
  DeliverablesBox: {
    padding: "54px",
  },
  btnBox: {
    display: "flex",

    alignItems: "flex-start",

    width: "100%",

    padding: "50px 70px 50px 70px",
  },
  projectBox: {
    width: "400px",
  },
  DeliverablesList: {
    marginTop: "18px",
  },

  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  dividerStyle: {
    backgroundColor: "rgba(84, 84, 84, 1)",
  },

  listItem: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",

    color: "#FFF2E3",
  },
  saveBtn: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    color: "#FFF2E3",
    borderRadius: "7px",
    linehHeigth: "24px",
    backgroundColor: "#0D0D0D",
    padding: "10px 30px 10px 30px",
    width: "max-content",

    height: "44px",
  },
  sendBtn: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 500,
    color: "#121212",
    borderRadius: "7px",
    linehHeigth: "24px",
    backgroundColor: "#FFF2E3",
    padding: "10px 30px 10px 30px",
    width: "max-content",
    height: "44px",
  },
  buttonBox: { display: "flex", gap: "16px" },
  workBox: {
    display: "flex",
    width: "100%",
    padding: "51px",
    gap: "51px",
  },
  titleStyle: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Inter",
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  workBtn: {
    fontFamily: "Inter",
    fontSize: "12px",
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    fontWeight: 400,
    borderRadius: "18.2px",
    border: " 0.5px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    height: "37px",
  },
  locationBox: {
    marginTop: "61px",
  },
  priceHour: {
    fontFamily: "Inter",
    fontSize: "16px",
    linehHeigth: "19.36px",
    fontWeight: 600,
    color: "#1CBAE0",
    padding: "6px 16px 6px 16px",
    borderRadius: "24px",
    width: "145px",
    backgroundColor: "#1D1D1D",
    display: "flex",
    height: "31px",
    justifyContent: "center",
    alignItems: "center",
  },
  month: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    linehHeigth: "19.36px",
    color: "#FFB9C7",
    padding: "6px 16px 6px 16px",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    width: "105px",
    height: "31px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnHourBox: {
    display: "flex",
    gap: "19px",
  },
  listNumber: {
    display: "flex",
    alignItems: "center",
    jutifyContent: "center",
    color: "515151",
  },
  listItem1: {
    fontFamily: "Inter",
    fontSize: "16px",
    minWidth: "215px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
    maxWidth: "max-content",
    height: "auto",
    display: "block",
    padding: "6px 15px 6px 15px",
    borderRadius: "10px",
    backgroundColor: "#333231",
    overflow: "hidden",
  },
  listItem2: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#3a5680",
    marginLeft: "10px",
    lineBreak:"anywhere" as any
  
  },
  location: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    marginBottom:24
  },
  listImg: {
    borderRadius: "4px",
    width: "350px",
  },
  imgTitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    linehHeigth: "24px",
    color: "#FFF2E3",
  },
  imgUser: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    linehHeigth: "24px",
    color: "#FFF2E3",
  },
  refBtn: {
    color: "#FFF2E3",
    lineHight: "13.33px",
    fontFamily: "Inter",
    fontWeight: 600,
    padding: "4px 13px 4px 13px",
    width: "51px",
    height: "21px",
    borderRadius: "13.99px",
    marginTop: "2px",
    backgroundColor: "828181",
  },
  DeliverItem: {
    display: "flex",
    gap: "14px",
    alignItems: "center",
  },
  DeliverItem2: { marginTop: "24px", display: "flex", gap: "14px" },
  DeliverItem3: {
    marginTop: "24px",
    display: "flex",
    gap: "14px",
    alignItems: "start",
    marginLeft: "10px",
  },
  imgDes: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4px",
  },
  iconNumber: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  imageIcon: {
    display: "flex",
    justifyContent: "center",
    gap: "6px",
    alignItems: "center",
  },
  imgeContainer: { display: "flex", gap: "15px", alignItems: "start" },
  refContainer: { display: "flex", gap: "55px", marginTop: "17px" },
  paddingBox: { padding: "50px 70px 50px 70px" },
  closeIconBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    width: "90%",
    maxWidth: "1490px",
    alignItems: "end",
    marginTop: "10px",
  },
  container: { width: "100%", height: "auto", overflow: "hidden" },
  closeIconcss: {
    cursor: "pointer",
    marginBottom:20
  },
};

// Customizable Area End
