export const dropUpIcon = require("../../assets/dropUpIcon.svg");
export const dropDownIcon = require("../../assets/dropDownIcon.svg");
export const leftArrowIcon = require("../../assets/leftArrowIcon.svg");
export const deleteIcon = require("../../assets/deleteIcon.svg");
export const editIcon = require("../../assets/editIcon.svg");
export const uploadIcon = require("../../assets/uploadIcon.svg");
export const searchIcon = require("../../assets/search.png");
export const closeIcon = require("../../assets/closeIcon.svg");
export const shareProject = require("../../assets/shareProject.svg");
export const imgIcon = require("../../assets/imgIcon.png");
export const PostOpportunityBanner = require("../../assets/PostOpportunityBanner.png");
export const likeIcon = require("../../assets/likeIcon.svg");
export const eyeIcon = require("../../assets/viewIcon.svg");
export const featureIcon = require("../../assets/saveIcon.svg");
export const checkIcon = require("../../assets/checkRing.svg");
export const uncheckIcon = require("../../assets/uncheckRing.svg");